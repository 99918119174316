import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent implements OnInit {  
  year : any;

  constructor(public baseService: BaseService, public translate: TranslateService) { }

  ngOnInit() {
  	const d = new Date();
	const n = d.getFullYear();
  	this.year = n;
  }

}
