import { Component, OnInit, Renderer2, ViewChild, ElementRef, NgZone, Output, EventEmitter } from '@angular/core';
import { PagesService } from 'src/app/pages/pages.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { Router } from '@angular/router';
import { MapsAPILoader} from '@agm/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { CartService } from 'src/app/shared/services/cart.service';
declare var $: any;
@Component({
  selector: 'app-web-search', 
  templateUrl: './web-search.component.html',
  styleUrls: ['./web-search.component.css']
})
export class WebSearchComponent implements OnInit {
  currentcats : any;
  currentCatId : any;
  subCatData : any;
  lat : number;
  lng : number;
  zoom: number;
  address: string;
  private geoCoder;
  restaurants = [];
  dishesList  = [];
  isMenuOpen : boolean = false;
  timeout: any = null;
  apiHit : boolean = false;
  defaultImage = "./assets/images/home-banner/defalut-image.png";
  
  private subscriptions: Subscription[] = [];
  @ViewChild('search', { static: false })
  searchElementRef: ElementRef;
  @ViewChild('keyword')
  public keywordElementRef: ElementRef;
  formattedAddress: string;
  showloader : boolean = false;
  @Output() valueChange = new EventEmitter();
  searchKeyword : any;
  city : any;
  locationList: any;
  showLocationPopup: boolean = false;
  locationName: undefined;
  showPopup: boolean;
  locationId: any;
  hidePopup: boolean;
  hideLocationDropDown: boolean;
  constructor(public baseService: BaseService, private router: Router, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, public pagesService: PagesService, private renderer: Renderer2, public translate: TranslateService, private cs: CookieService, public cartService: CartService) { 
    
  }

  ngOnInit(): void {
    this.subscriptions.push(     
      this.baseService.userCoords.subscribe((value) => {
        if(value  && value[0])
        {
          this.lat = value[0];
          this.lng = value[1];
          this.formattedAddress = value[2];
          this.city = value[3];
        } 
      })
    );
    if(!this.cs.get('locationCookie')) {
      this.hideLocationDropDown = true;
    }
    if(this.baseService?.storeData?.is_loc_popup_enabled === '1') {
      this.subscriptions.push(
        this.baseService.setLocations.subscribe(res => {
          if(res.length) {
            this.hideLocationDropDown = false;
            this.showLocationPopup = true;
            this.hidePopup = true;
            this.locationName = res[res.length - 1];
          }
          else {
            if(this.cs.get('locationCookie')) {
              let data = JSON.parse(this.cs.get('locationCookie'));
              let arr = [...data];
              this.hidePopup = true;
              this.hideLocationDropDown = false;
              this.showLocationPopup = true;
              this.locationName = arr[1];
            }
          }
        })
      );
    }
  }

  ngAfterViewInit(){
    
    if(this.baseService.isDesktopDevice && this.showLocationPopup === false)
    {
      this.renderer.listen('window', 'click',(e:Event)=>{
       if(e.target != this.keywordElementRef.nativeElement){
            this.isMenuOpen=false;
            this.keywordElementRef.nativeElement.value = '';
        }
      });
    }    
   }

  ngAfterContentInit()
   {
    if(this.showLocationPopup === false) {
      this.autocomplete();
    }
   }


   autocomplete()
   {
    setTimeout(() => {
      this.mapsAPILoader.load().then(() => {
      //this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
        autocomplete.addListener("place_changed", () => {
          this.showloader = true;
          this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            this.formattedAddress = place.formatted_address;
            //verify result
            if (place.geometry === undefined || place.geometry === null) {
            return;
            }
            //set latitude, longitude and zoom
            this.lat = place.geometry.location.lat();
            this.lng = place.geometry.location.lng();
            this.baseService.setLatlng(this.lat, this.lng, this.formattedAddress, place.address_components[0]['long_name'] );
            this.zoom = 12;
              setTimeout(() => {
                this.showloader = false;
              }, 1500 );
          });
        });
      });
    }, 4000 );    
   }

   getLocationList() {
     this.hidePopup = false;
    if(localStorage.getItem('locationListData')) {
      this.locationList = JSON.parse(localStorage.getItem('locationListData'));
      if(this.locationList && this.baseService?.isMobile) {
        setTimeout(() => {
          $('#locationPopup2').appendTo("body").modal('show');
        }, 500);
      }
    }
   }

   getStoreData(locationId, locationName) {
    this.locationId = locationId;
    this.locationName = locationName;
    if(this.baseService?.isDesktopDevice) {
      this.baseService.setLocation([this.locationId, this.locationName]);
      this.cs.set('locationCookie', JSON.stringify([this.locationId, this.locationName]), 365);
      this.cartService.clearCart();
      this.router.navigate(['/'+this.baseService?.storeType+'/home']);
    }
  }

  submitLocation() {
    if(this.locationId && this.locationName) {
      $('#locationPopup2').modal('hide');
      this.baseService.setLocation([this.locationId, this.locationName]);
      this.cs.set('locationCookie', JSON.stringify([this.locationId, this.locationName]), 365);
      this.cartService.clearCart();
      this.router.navigate(['/'+this.baseService?.storeType+'/home']);
    }
  }


   getNotification(event)
   {
    console.log("event= ", event);
   }

   ngOnDestroy() {
      this.isMenuOpen  = false;
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
   }

   onKeySearch(event: any) {
    this.searchKeyword = event.target.value;
     //this.apiHit = true;
     if(this.timeout){
      clearTimeout(this.timeout);
    }
    var $this = this;
    this.timeout = setTimeout(function () {
      if ((event.keyCode != 13) && (event.target.value.trim())) {        
        $this.getSearchRestaurantsList(event.target.value.trim());
      }
      //this.isMenuOpen = true;
      //this.isMenuOpen = !this.isMenuOpen;
    }, 500);
  }

   getResults()
  {
    // 
    // if(this.keywordElementRef.nativeElement.value)
    // {
      const key = this.keywordElementRef.nativeElement.value ? this.keywordElementRef.nativeElement.value : "all";
      this.getSearchRestaurantsList(key);
    // }
  }


  getSearchRestaurantsList(keyword)
  {
    this.apiHit = true;
    //this.restaurants = [];
    //this.dishesList  = [];
    //const key = keyword ? keyword : "all";
    const restodata = "lat="+this.lat+"&lng="+this.lng+'&search_by=Keyword&keyword='+keyword+'&id='+(this.locationId ? this.locationId : '')+'&name='+(this.locationName ? this.locationName : '');
    this.subscriptions.push(
      this.pagesService.getRestaurantsList(restodata).subscribe((res) => {
        if (res) {
          this.apiHit = false;
          this.isMenuOpen  = true;
          this.restaurants = res['data'] ? res['data'] : [];
          if(this.restaurants.length) {
            this.calculateStoreTimmings(this.restaurants);
          }
          this.dishesList  = res['dishes'] ? res['dishes'] : [];
        }
      })
    );     
  }

  calculateStoreTimmings(allRestaurants)
  {
    allRestaurants.filter((singleResto) => {
      if(singleResto.store_status == '1') {
        if(singleResto.timimg?.is24x7_open == '1') {
          singleResto.storeOpen = true;
        } else {
          var a = new Date();
          var weekdays = new Array(7);
          weekdays[0] = "sun";
          weekdays[1] = "mon";
          weekdays[2] = "tue";
          weekdays[3] = "wed";
          weekdays[4] = "thu";
          weekdays[5] = "fri";
          weekdays[6] = "sat";
          var r = weekdays[a.getDay()];
          if(singleResto?.timimg?.store_open_days && ((singleResto?.timimg?.store_open_days).split(',')).indexOf(r) > -1) {
            const openHoursFrom = this.formatTime(singleResto?.timimg.openhours_from);
            const openHoursTo   = this.formatTime(singleResto?.timimg.openhours_to);
            if(Date.parse('01/01/2011 '+(new Date().getHours()+':'+new Date().getMinutes())) >= Date.parse('01/01/2011 '+ openHoursFrom) && Date.parse('01/01/2011 '+(new Date().getHours()+':'+new Date().getMinutes())) <= Date.parse('01/01/2011 '+ openHoursTo)){
                singleResto.storeOpen = true;
              } else
              {
                singleResto.storeOpen = false;
              }
            } else
            {
              singleResto.storeOpen = false;
            }
          }   
      } else {
        singleResto.storeOpen = false;
      }                
      });
      this.restaurants = allRestaurants;   
  }

  formatTime(time) {
    const split = time.split(':');
    const min = split[1].split('')[0] + split[1].split('')[1];
    const am_pm = split[1].split('')[2] + split[1].split('')[3];
    let hours = split[0];
    if (hours == '12') {
      hours = '00';
    }
    if (am_pm == 'pm') {
      hours = parseInt(hours, 10) + 12;
    }
    return hours + ':' + min;
  }


  searchRestos()
  {
    const key = this.keywordElementRef.nativeElement.value ? this.keywordElementRef.nativeElement.value : "all";
    this.router.navigate(['/stores/search/'+key]);
  }

}
