<div class="icon-nav align-item-center d-flex">
  <div class="col-lg-12 p-0"  *ngIf="baseService.isDesktopDevice">
    <div class="main-nav-center">
        <app-navbar></app-navbar>
    </div>
  </div>
  <ul *ngIf="baseService.isMobile"> 
    <!-- <li class="order-online-list" *ngIf="homePage && baseService.isDesktopDevice">
        <a class="order-online-link" [ngStyle]="{'color': baseService?.storeData?.web_homepage_headings?.order_online?.color}" *ngIf="homePage" (click)="baseService.getFirstCatClicked()">{{baseService?.storeData?.web_homepage_headings?.order_online?.title || 'Order Now'}}</a>
    </li>   -->
    <li [ngClass]="{'home-active' : homeActive == true}" class="onhover-div mobile-search search-widgets pl-lg-0" id="search-widgets" *ngIf="baseService?.storeData?.bottom_navigation == '1' || !router.url.includes('restaurants')">
      <div>
          <!-- <div class="authentication-page" *ngIf="baseService.isDesktopDevice && !homePage">
            <div class="container">
              <div class="filter-wrapper search-block">
                <div class="keyword-wrapper-web keyword-wrapper">
                   <input type="text" id="keyword" (click)="openSearch()" autocomplete="off"  placeholder="Search for Dishes"/>
                  <button type="submit" class="btn" (click)="returnData()"><i class="ti-search"></i></button>
                </div>
              </div> 
            </div>
          </div> -->
        <a [routerLink]="'/'+baseService?.storeType+'/home'" (click)="closeSearch()"><i class="ti-home"></i>
        <span class="menu_name">{{'home' | translate}}</span>
        </a>
      </div>
    </li>
    <li [ngClass]="{'search-active' : searchActive == true}" class="onhover-div mobile-setting" *ngIf="baseService?.storeData?.bottom_navigation == '1' || !router.url.includes('restaurants')">
      <div>
        <!-- <img src="assets/images/icon/setting.png" class="img-fluid" alt=""> -->
        <a class="bottom-search" (click)="openSearch()"><i class="ti-search"></i>
          <span class="menu_name">{{'search' | translate}}</span>
        </a>
      </div>
    </li>
    <li class="onhover-div mobile-cart" *ngIf="!baseService?.catalogMode && baseService?.storeData?.bottom_navigation == '1' || !router.url.includes('restaurants')">
      <div> 
        <a (click)="closeSearch()" [routerLink]="'/'+baseService?.storeType+'/cart'">
          <i class="ti-shopping-cart"></i>
           <span class="menu_name">{{'cart' | translate}}</span>
        </a>
      </div>
      <span [routerLink]="'/'+baseService?.storeType+'/cart'" *ngIf='shoppingCartItems && baseService.isMobile' [class]="shoppingCartItems ? 'cart_qty_cls d-sm-none d-lg-block' : ''" class="cart_value">{{shoppingCartItems}}</span>
    </li>
  </ul>
</div>