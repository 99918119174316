<!-- <div class="collection-mobile-back">
    <label class="category-header">
      <h5></h5>
      <span class="close-btn" (click)="mobileFilterBack()">×</span>
    </label>
  </div> -->
<div class="" *ngIf="baseService.isMobile">
  <div class="category-header">
    <div class="col-12 p-0">
      <a class="btn">
        <div class="cat-title">
          <h4>
            {{'order-by-category' | translate}}
          </h4>
        </div>
        <span class="close-btn" (click)="mobileFilterBack()">×</span>
      </a>
    </div>
   </div>
   <div class="cat-block">
    <div *ngFor="let category of categories; let i = index" class="collection-collapse-block border-0" id="blockContainer{{i}}">
      <h3 class="collapse-block-title collapse-cat-title arrow_bottom" (click)="toggleCatTab(i)">{{category?.title}}</h3>
      <div class="collection-collapse-block-content" id="blockContent{{i}}" [style.display]="'none'">
        <div class="collection-brand-filter">
          <ul class="category-list" *ngIf="category && category.sub_category">
            <li *ngFor="let subCat of category.sub_category" class="cursorPointer"><a (click)="mobileFilterBack()" [routerLink]="['/'+baseService?.storeType+'/collection/',modifyCatTitle(category.title),subCat.id]">{{subCat?.title.toLowerCase()}}</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
