import { Component, Inject, OnInit, OnDestroy, Input, ViewChild, ElementRef, NgZone} from '@angular/core';
import { LandingFixService } from '../../services/landing-fix.service';
import { DOCUMENT } from "@angular/common";
import { WINDOW } from '../../services/windows.service';
import { CartItem } from '../../classes/cart-item';
import { CartService } from '../../services/cart.service';
import { BaseService } from '../../services/base.service';
declare var $: any;
import { ShareService } from '@ngx-share/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { iconpack } from 'src/icons';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { PagesService } from 'src/app/pages/pages.service';
import { Meta, DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { HeaderService } from '../../services/header.service';
import { Location } from "@angular/common";
import { TranslateService } from '@ngx-translate/core';

declare const google: any;

@Component({
  selector: 'app-header-three',
  templateUrl: './header-three.component.html',
  styleUrls: ['./header-three.component.scss']
})
export class HeaderThreeComponent implements OnInit, OnDestroy {
  @Input() storeData:any;
  @Input() url:any;
  referApiHit = false;
  referMessage = '';
  shareUrl = '';
  storeName = "";
  defaultImage = "./assets/images/home-banner/default.jpg";
  zoom: number = 15;
  lat: number = 30.7046;
  lng: number = 76.7179;
  maplat : number;
  maplng : number;
  private geoCoder;
  @ViewChild('search')
  public searchElementRef2: ElementRef;
  formattedAddress: string = '';
  ons: Subscription[] = [];
  public shoppingCartItems  :   CartItem[] = [];
  private subscriptions: Subscription[] = [];
  public multiDataOn : boolean = false;
  public nothome : boolean = false;
  private history: string[] = []
  mobilesaerchFlag : boolean = false;
  locationName: string;
  locationId: string;
  constructor(@Inject(DOCUMENT) private document: Document,public translate: TranslateService,public _cs: CookieService, public pagesService:PagesService, library: FaIconLibrary, public share: ShareService,
    @Inject(WINDOW) private window,private fix: LandingFixService, public cartService: CartService, public baseService: BaseService,public toastrService: ToastrService,public router: Router, private ngZone: NgZone,private meta: Meta, public sanitizer:DomSanitizer, public headerService: HeaderService, private location: Location, private _route: ActivatedRoute) { 
    
        this.subscriptions.push(this.cartService.getItems().subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems));
        library.addIcons(...iconpack);
        this.subscriptions.push(this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              document.getElementById("shareLink").style.display = "none";
              this.history.push(event.urlAfterRedirects)
              this.url = event.url; 
              if (location.path() == "" || location.path() == "/partners") {
                this.nothome = false;
              } else {
                this.nothome = true;
              }
            }
          })
        );
  }

  ngOnInit() {
    this.subscriptions.push(
       this.baseService.isMultipleStore().subscribe(val => {
        if (val === true) {
          this.multiDataOn = true;
        }
      })
    );
    const modal = document.getElementById("shareLink");
    window.onclick = function(event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    }

    this.subscriptions.push(     
      this.baseService.userCoords.subscribe((value) => {
        if(value  && value[0])
        {
          this.lat = parseFloat(value[0]);
          this.lng = parseFloat(value[1]);
          this.formattedAddress = value[2];
        } 
      })
    );
    if(sessionStorage.getItem('isLocPop') === '1') {
      this.subscriptions.push(
        this.baseService.setLocations.subscribe(res => {
          if(res.length) {
            const arr = [...res];
            this.locationName = arr[1];
            this.locationId = arr[0];
          }
          else {
            if(this._cs.get('locationCookie')) {
              let data = JSON.parse(this._cs.get('locationCookie'));
              const arr = [...data];
              this.locationName = arr[1];
              this.locationId = arr[0];
            }
          }
        })
      );
    }
    $.getScript('assets/js/menu.js');
  }

  externalForm(link) {
    window.location.href = link;
  }
  

  goBack()
  {
    this.history.pop()
    if (this.history.length > 0) {
      this.location.back()
    } else {
      this.router.navigateByUrl('/')
    }
    return false;
  }


  onchange(data){
    if(data){
      this.referApiHit = true;
      const userId = this._cs.get('UserInfo') ? JSON.parse(this._cs.get('UserInfo'))['id'] : '';
      if(this.baseService?.storeData?.is_referer_fn_enable)
      {
        this.shareUrl = this.baseService?.storeData?.domain+'/?sid='+this.baseService?.storeData?.id+'&uid='+userId+'&refer=2';
      }
       else
      {
        this.shareUrl = this.baseService?.storeData?.domain+'/?sid='+this.baseService?.storeData?.id+'&uid='+userId+'&refer=1';
      }
      
      this.storeName = this.baseService?.storeData?.store_name;
      document.getElementById("shareLink").style.display = "block";
      if(this.baseService?.storeData?.is_referer_fn_enable){
        this.getReferDetails();
      }else{
        this.referApiHit = false;
      }
    }
  }
  
  getReferDetails(){
    const userId = this._cs.get('UserInfo') ? JSON.parse(this._cs.get('UserInfo'))['id'] : '';
    //const userId = localStorage.getItem('UserInfo') ? JSON.parse(localStorage.getItem('UserInfo'))['id'] : '';
      if(userId){
        const referData = 'user_id=' + userId + '&device_id=111&device_token=111&platform=web';
        this.subscriptions.push(   
          this.pagesService.getReferDetails(referData).subscribe((res) => {
            this.referApiHit = false;
            if (res['status']) {
              this.referMessage = res['ReferEarn']['shared_message']
              this.meta.updateTag({name: 'og:description', content: this.referMessage});
            }
          })
        );
      }
    }

    openLocationPopup() {
      this.baseService.openLocation('true');
    }


    openLocation()
    {
      this.mobilesaerchFlag = true;
      this.maplat = this.lat;
      this.maplng = this.lng;
      
      this.getAutoCompleteSearch();
      $('#addressByRadius1').modal('show');
    }

    markerDragEnd($event: any) {
      this.maplat = $event.coords?.lat;
      this.maplng = $event.coords?.lng;  
      this.getGeoLocation(this.maplat, this.maplng);
    }


    getGeoLocation(lat: number, lng: number) {

      if (navigator.geolocation) {
          let geocoder = new google.maps.Geocoder();
          let latlng = new google.maps.LatLng(lat, lng);
          let request = {
              latLng: latlng
          };
          geocoder.geocode(request, (results, status) => {
              if (status == google.maps.GeocoderStatus.OK) {
                  let result = results[0];
                  this.formattedAddress = result['formatted_address'];
                  this.baseService.setLatlng(lat, lng, this.formattedAddress );
              }
          });
      }
  }

  clearSearchInput()
  {
     this.searchElementRef2.nativeElement.value = '';
  }


    getAutoCompleteSearch()
    {
      setTimeout(() => {
        this.geoCoder = new google.maps.Geocoder;
         let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef2.nativeElement);
         autocomplete.addListener("place_changed", () => {
         this.ngZone.run(() => {
         //get the place result
         let place: google.maps.places.PlaceResult = autocomplete.getPlace();
         this.formattedAddress = place.formatted_address;
         //verify result
         if (place.geometry === undefined || place.geometry === null) {
           return;
         }
           this.maplat = place.geometry.location.lat();
           this.maplng = place.geometry.location.lng();
           this.baseService.setLatlng(this.maplat, this.maplng, this.formattedAddress, place.address_components[0]['long_name']);
           this.zoom = 12;
         });
         });
      }, 3000);
    }
 
    closeMap() {
      $('#addressByRadius1').modal('hide');
      this.mobilesaerchFlag = false;
    }

    copyMessage() {
      const el = document.createElement('textarea');
      if(this.baseService?.storeData?.is_referer_fn_enable){
        el.value = this.referMessage;
      }else{
        el.value = this.shareUrl;
      }
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.toastrService.success("Copied to clipboard", 'Success!');
      this.closeShareModal();
    }

    openMultiPopUp()
    {
      $("#multipleStoreData").modal("show");
      return false;
    }


    // facebookShare(){
    //   window.open('http://www.facebook.com/sharer.php?s=100&;p[title]='+encodeURI(this.storeName)+'&p[summary]='+encodeURI(this.referMessage)+'&p[url]='+encodeURI(this.shareUrl)+'&p[images][0]='+encodeURI(this.baseService?.storeData?.banner)+''+'','sharer','toolbar=0,status=0,width=548,height=325');
    // }

   // Modify Category Title
   modifyCatTitle(category){
    let modified = category.replace(/[^&a-zA-Z ]/g,'-')
    return (modified.replace(/ /g,"-")).toLowerCase();
  }

  closeShareModal(){
    document.getElementById("shareLink").style.display = "none";
  }

  // For mobile filter view
  public mobileFilter() {
    $('.collection-filter').css("left", "0px");
  }

  openNav() {
  	this.fix.addNavFix();
    $.getScript('assets/js/menu.js');
  }

  closeNav() {
     this.fix.removeNavFix();
  }

  displayCopied(){
    this.toastrService.success('Copied to clipboard', 'Success!')
  }

  goForLogin(){
    $('#refererAlertPopup').modal('hide');
    this.router.navigate(['/'+this.baseService?.storeType+'/login'])
  }
   
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
   }
}
   
