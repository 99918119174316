<!--footer section -->
<footer class="footer-light" *ngIf="baseService?.screenOptions?.web_footer?.display">
	<section class="section-b-space light-layout" *ngIf="baseService.isDesktopDevice">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-4">
					<div class="row">
						<div class="col-sm-12 footer_content">							
								<ul class="nav-footer">
									<h4>{{baseService?.pagesData?.homepage?.footer_useful_links}}</h4>									
									<li routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> 
										<a [routerLink]="'/'+baseService?.storeType+'/terms'">{{'terms-conditions' | translate}}</a>
									</li>
									<li routerLinkActive="active">
										<a [routerLink]="'/'+baseService?.storeType+'/privacy'">{{'privacy-policy' | translate}}</a>
									</li>
								
									<li routerLinkActive="active">
										<a [routerLink]="'/'+baseService?.storeType+'/refund'">{{'refund-policy' | translate}}</a>
									</li>
									<li routerLinkActive="active">
										<a [routerLink]="'/'+baseService?.storeType+'/shipping'">{{'shipping-policy' | translate}}</a>
									</li>
									<li routerLinkActive="active">
										<a [routerLink]="'/'+baseService?.storeType+'/faq'">{{'FAQ' | translate}}</a>
									</li>
									<li *ngIf="baseService?.storeData?.type == 'restaurant'" routerLinkActive="active">
										<a [routerLink]="'/'+baseService?.storeType+'/partners'">{{'register-restaunrant' | translate}}</a>
									</li>
									<li *ngIf="baseService?.storeData?.type == 'grocery'" routerLinkActive="active">
										<a [routerLink]="'/'+baseService?.storeType+'/partners'"> {{'register-store' | translate}}</a>
									</li>
									<li routerLinkActive="active">
										<a [routerLink]="'/'+baseService?.storeType+'/runners'">Deliver with Afro Yum</a>
									</li>
									<li routerLinkActive="active" *ngIf="(baseService?.storeData?.show_promotion_links == '2' || baseService?.storeData?.show_promotion_links == '3') && baseService?.storeData?.show_promotion_links != '4'">
										<a *ngIf="baseService?.storeData?.merchant_signup_link; else gotoOffers" (click)="externalForm(baseService?.storeData?.merchant_signup_link)" class="capitalize">{{'promotion' | translate}}</a>
										<ng-template #gotoOffers>
										<a [routerLink]="['/offers']" class="capitalize">{{'promotion' | translate}}</a>
										</ng-template>
									</li>
									<li routerLinkActive="active" *ngIf="(baseService?.storeData?.show_promotion_links == '2' || baseService?.storeData?.show_promotion_links == '3') && baseService?.storeData?.show_promotion_links != '4'">
										<a *ngIf="baseService?.storeData?.runner_signup_link; else gotoContacts" (click)="externalForm(baseService?.storeData?.runner_signup_link)" class="capitalize">{{'support' | translate}}</a>
										<ng-template #gotoContacts>
										<a [routerLink]="['/contact']" class="capitalize">{{'support' | translate}}</a>
										</ng-template>
									</li>
								</ul>
						</div>
					</div>
				</div>
				<div class='col-lg-4 col-md-4'>
					<div class="row">
						<div class="col-sm-12 footer_content">							
								<ul class="nav-footer">
									<h4>{{baseService?.pagesData?.homepage?.footer_contact_us}}</h4>
									<li *ngIf="baseService?.storeData?.contact_number">
										<p><a target="_blank" href="tel:{{baseService?.storeData?.contact_number}}">{{baseService?.storeData?.contact_number}}</a></p>
									</li>
									<li *ngIf="baseService?.storeData?.contact_email">
										<p><a target="_blank" href="mailto:{{baseService?.storeData?.contact_email}}">{{baseService?.storeData?.contact_email}}</a></p>
									</li>
									<li *ngIf="baseService?.storeData?.city">
										<p>{{baseService?.storeData?.city+ ', ' +baseService?.storeData?.country}}</p>
									</li>
									<li>
										<a  [routerLink]="'/'+baseService?.storeType+'/contact'">{{'help-support' | translate}}</a>
									</li>
								</ul>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4">
					<div class="download">
						<h4 *ngIf="socialValues?.heading1">{{socialValues?.heading1}}</h4>
						<p *ngIf="socialValues?.heading2"><b>{{socialValues?.heading2}}</b></p>
					 </div>
					 <div class="social_links" *ngIf="socialExists">
						 <!-- <h4>FOLLOW US ON</h4> -->
                        <ul class="social-media">
						   <li *ngIf="socialValues?.facebook" class="facebook"><a target="_blank" href="{{socialValues?.facebook}}"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
						   <li *ngIf="socialValues?.twitter" class="twitter"><a target="_blank" href="{{socialValues?.twitter}}">
							<svg id="Capa_1" enable-background="new 0 0 1226.37 1226.37" viewBox="0 0 1226.37 1226.37"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="m727.348 519.284 446.727-519.284h-105.86l-387.893 450.887-309.809-450.887h-357.328l468.492 681.821-468.492 544.549h105.866l409.625-476.152 327.181 476.152h357.328l-485.863-707.086zm-144.998 168.544-47.468-67.894-377.686-540.24h162.604l304.797 435.991 47.468 67.894 396.2 566.721h-162.604l-323.311-462.446z">
							</path>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
							<g></g>
						    </svg>
					       </a></li>
						   <li *ngIf="socialValues?.linkedin" class="linkedin"><a target="_blank" href="{{socialValues?.linkedin}}"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
						   <li *ngIf="socialValues?.youtube" class="youtube"><a target="_blank" href="{{socialValues?.youtube}}"><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li>
						   <li *ngIf="socialValues?.instagram" class="instagram" ><a target="_blank" href="{{socialValues?.instagram}}"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
						</ul>
					 </div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer" *ngIf="baseService.isDesktopDevice">
		<div class="container">
			<app-copyright></app-copyright>
		</div>
	</div>
	  <!-- Multiple Store Select Popup -->
      <div class="modal fade select_city" id="multipleStoreData" tabindex="	-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12 col-sm-12 col-xs-12  p-0">
				 <div class="text-center"><h4>{{'select-your-city' | translate}} </h4></div>
				  <div class="your_city">
                  	<div class="radio" *ngFor="let store of baseService?.multipleData; let i = index">
						<label class="container_multiplestore">{{store.store_name}}
						<input type="radio" [checked]="storeId == store.id ? 'checked' :''" name="multiplestore" value="store.store_url" (click)="changeStore(store.store_url);">
						<span class="checkmark-city"></span>
						</label>
					</div>
				</div>  				
              </div>
            </div>   
          </div>
        </div>
      </div>
    </div>
	<div class="modal fade" id="getAppmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" *ngIf="baseService?.isMobile">
		<div class="modal-dialog modal-dialog-centered" role="document">
		  <div class="modal-content">
			<div class="modal-button-cross">
			  <button (click)="hideAppModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">&times;</span>
			  </button>
			</div>	
			<div class="modal-body">
				<ul class="store-app">
					<li *ngIf="deviceType == 'iPhone'"><a href="{{baseService?.storeData?.iphone_share_link}}">
						<div class="store-image"><img src="{{baseService?.storeData?.app_theme_icons?.icon_72_72}}" alt=""></div>
						<div class="store-name"><span>{{baseService?.storeData?.name}}</span></div>
						<div class="store-button"><button class="btn">Get App</button></div></a>
					</li>
					<li *ngIf="deviceType == 'Android'"><a target="_blank" href="{{baseService?.storeData?.android_share_link}}" >
						<div class="store-image"><img src="{{baseService?.storeData?.app_theme_icons?.icon_72_72}}" alt=""></div>
						<div class="store-name"><span>{{baseService?.storeData?.name}}</span></div>
						<div class="store-button"><button class="btn">Get App</button></div></a>
						</li>
					<li class="browse">
						<div class="store-image"><img src="assets/images/browser.png" alt=""></div>
						<div class="store-name"><span>Browser</span></div>
						<div class="store-button"><button (click)="hideAppModal()" class="btn continue_now">Continue</button></div>
					</li>
				</ul>
			</div>
		  </div>
		</div>
	</div>
</footer>