import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CartItem } from '../../../../shared/classes/cart-item';
import { CartService } from '../../../../shared/services/cart.service';
import { ProductsService } from '../../../../shared/services/products.service';
import { BaseService } from '../../../../shared/services/base.service';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { NavbarClassService } from 'src/app/shared/services/navbarClass.service';
declare var $: any;

@Component({
  selector: 'app-header-widgets',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  @Input() shoppingCartItems  :   any;
  url : any;
  public show     :   boolean = false;
  public homePage :   boolean = true;
  private subscriptions: Subscription[] = [];
  homeActive: boolean = false;
  searchActive: boolean = false;

  constructor(private translate: TranslateService, private cartService: CartService,
   public productsService: ProductsService,public baseService: BaseService,private toastrService: ToastrService, public router: Router, private _cs: CookieService, public navbarClassService: NavbarClassService) {
       
    }

  ngOnInit() {  

    if(this.router.url.includes('home')) {
      this.homeActive = true;
    }

    this.subscriptions.push(
      this.navbarClassService.isSearchClosed.subscribe(value => {
        if(value == true && !this.router.url.includes('offers') && !this.router.url.includes('orders')) {
          this.homeActive = true;
          this.searchActive = false;
        }
        if(value == true && !this.router.url.includes('home') && !this.router.url.includes('orders')) {
          this.searchActive = false;
          this.homeActive = false;
        }
        if(value == true && !this.router.url.includes('home') && !this.router.url.includes('offers')) {
          this.searchActive = false;
        }
      })
    );

    this.subscriptions.push(
      this.navbarClassService.toHome.subscribe(value => {
        if(value == true) {
          this.homeActive = true;
          this.searchActive = false;
        }
      })
    );
    

    this.subscriptions.push(
      this.navbarClassService.searchOpen.subscribe(value => {
        if(value == true) {
          this.homeActive = false;
          this.searchActive = true;
        }
      })
    );

    this.subscriptions.push(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.url = event.url;  
          if(this.url.indexOf('/merchant/') > -1 || this.url.indexOf('/product/') > -1)
          {
            this.homePage = false;
          }else
          {
             this.homePage = true;
          }
          if(this.router.url.includes('orders') || this.router.url.includes('offers')) {
            this.homeActive = false;
          }
        } 
      })
    );
      if(this.router.url.indexOf('/merchant/') > -1 || this.router.url.indexOf('/product/') > -1)
      {
        this.homePage = false;
      }else
      {
         this.homePage = true;
      }
  }

  

  public updateCurrency(curr) {
    this.productsService.currency = curr;
  }

  public changeLanguage(lang){
    this.translate.use(lang)
  }
  

  public openSearch() {
    this.navbarClassService.isSearchOpen(true);
    //let tsnString = (document.getElementById("keyword-web") as HTMLTextAreaElement).value;    
    $('.search-overlay').show(function () {
          //document.getElementById("exampleInputPassword1").focus();
         //$(".keyword-wrapper-web").css("display","none");
       });
    //this.baseService.showSearch = true;
  }

  public returnData()
  {
    return false;
  }


  public closeSearch() {
    this.navbarClassService.isToHome(true);
    this._cs.delete('viewall', '/');
    //console.log("close");
    $(".keyword-wrapper-web").show();
    $(".search-overlay").css("display","none");    
  }


  // public getTotal(): Observable<number> {
  //   return this.cartService.getTotalAmount();
  // }

  public removeItem(item: CartItem) {
    this.cartService.removeFromCart(item);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
   }

}
