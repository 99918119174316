import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpService } from './http.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import swal, { SweetAlertOptions } from 'sweetalert2';

declare var $:any;
// let categories = [];
@Injectable()
export class BaseService {
  public currency : string = '&#8377;';
  public currencyType: string = '';
  public catalogMode : boolean = false;   
  public categories: any[];
  categories$ :BehaviorSubject<any[]> = new BehaviorSubject([]);
  public storeData;
  public pagesData;
  public menuData;
  public screenOptions : any = [];
  public storeId?: any;
  language = 'eng';
  public lang: BehaviorSubject<string> = new BehaviorSubject(this.language);
  lang$: Observable<string> = this.lang.asObservable();
  userCoords: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  userCoords$: Observable<Array<any>> = this.userCoords.asObservable();
  public currentStoreId: BehaviorSubject<number> = new BehaviorSubject(this.storeId);
  currentStoreId$: Observable<number> = this.currentStoreId.asObservable();

  public brandID: BehaviorSubject<number> = new BehaviorSubject(parseInt(localStorage.getItem('marketPlaceBrandId')));
  brandID$: Observable<number> = this.brandID.asObservable();
  
  public currentStoreData: BehaviorSubject<[]> = new BehaviorSubject([]);
  currentStoreData$: Observable<[]> = this.currentStoreData.asObservable();

  public menuDataAccessed: BehaviorSubject<[]> = new BehaviorSubject([]);
  menuDataAccessed$: Observable<[]> = this.menuDataAccessed.asObservable();

  public setLocations: BehaviorSubject<[]> = new BehaviorSubject([]);
  public openLocations: BehaviorSubject<string> = new BehaviorSubject('');

  
  public storeTheme = '#75990b';
  public storeType = '';
  private baseUrl = 'v1/marketplace/';
  public showSearch;
  public url;
  public host;
  public localDomains = ["localhost:4200"];
  public currentCategory = null;
  public currentSubCategoryId = null;
  public deviceData = "device_id=1111&device_token=111&platform='web'";
  public isMobile : boolean = false;
  public isDesktopDevice: boolean = false;
  public device : any = "web";
  public isMultipleStore$ = new BehaviorSubject(false);
  public multipleData = [];
  IP : any;
  webData: any;
  showButton: boolean = false;
  showLanguage: boolean = false;
  addonFeatureOn: boolean;
  brandName: any;
  locationId: any;
  constructor(
    private httpService: HttpService,
    private router: Router, public _cs: CookieService,
    
  ) {    
      this.url  = window.location.href;
      this.host = window.location.host;  
      this.showSearch = false;    
     if(window.innerWidth >= 991)
      {
        this.isDesktopDevice = true;
        this.isMobile = false;
        this.device   = "web";
      } else
      {
        this.isDesktopDevice = false;
        this.isMobile = true;
        this.device   = "pwa";
      }
      
  }

  multipStoreData()
  {
     this.httpService.post(this.storeId+'/'+this.baseUrl+'getMultiStoresName').subscribe((res) => {
       if (res['success']) {
          this.isMultipleStore$.next(true);
          this.multipleData    = res['data'];
       }
     });
  }

  setLocation(data) {
    this.locationId = data[0];
    this.getCategories();
    this.setLocations.next(data);
  }

  openLocation(data) {
    this.openLocations.next(data);
  }

  isMultipleStore() {
    return this.isMultipleStore$.asObservable();
  }

  getPosition(): Promise<any>{
    if (navigator.geolocation) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resp => {
            resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
          },
          err => {
            reject(err);
          }, {maximumAge:60000, timeout:20000, enableHighAccuracy:true});
      });
    }
  }


  getFirstCatClicked()
  {
    this.currentStoreId.subscribe((value) => {
       const storeSlug = (localStorage.getItem('grocersStoreDetails') && JSON.parse(localStorage.getItem('grocersStoreDetails'))) ? JSON.parse(localStorage.getItem('grocersStoreDetails'))?.slug : value;
       this.router.navigate(['/merchant/'+storeSlug], { queryParamsHandling: "merge" });
    });   
  }

  modifyCatTitle(category){
    let modified = category.replace(/[^&a-zA-Z ]/g,'-')
    return (modified.replace(/ /g,"-")).toLowerCase();
  }


  getLatlng()
  {
    const latlng : any = [];
    if(!this._cs.get('latCookie') && !this._cs.get('lngCookie') && !this._cs.get('addressCookie') && !this._cs.get('MarketplaceCity')) {
      latlng.push(this.webData?.lat);
      latlng.push(this.webData?.lng);
      latlng.push(this.webData?.address);
      latlng.push(this.webData?.city);
      this.userCoords.next(latlng);
    } else {
      latlng.push(this._cs.get('latCookie'));
      latlng.push(this._cs.get('lngCookie'));
      latlng.push(this._cs.get('addressCookie'));
      latlng.push(this._cs.get('MarketplaceCity'));
      this.userCoords.next(latlng);

    }
  }

  setLatlng(lat : number, lng : number, formattedAddress: string, city = '')
  {
    const latlng : any = [];
    latlng.push(lat);
    latlng.push(lng);
    latlng.push(formattedAddress);
    latlng.push(city);
    this._cs.set('latCookie', latlng[0], 365);
    this._cs.set('lngCookie', latlng[1], 365);
    this._cs.set('addressCookie', latlng[2], 365);
    this._cs.set('MarketplaceCity', latlng[3], 365);
    this.userCoords.next(latlng);
  }


  calculateDistanceScreens(screens)
  {
    screens.filter((singleScreen) => {     
      this.screenOptions[singleScreen['section']] = singleScreen;
    });
  }

  storeDetails(deviceData) {
    const versionData = deviceData + '&lang='+this.lang;
     this.httpService.getThirdParty("https://api.ipify.org?format=json").subscribe((res:any)=>{
      this.IP = res.ip;
        this.httpService.post(this.storeId+'/'+this.baseUrl+'homescreen/version?ip='+this.IP, versionData).subscribe((res) => {
        if (res['success']) {
          this.currentStoreData.next(res['brand']);
          this.storeData = res['brand']; 
          sessionStorage.setItem('isLocPop', this.storeData?.is_loc_popup_enabled);
          this.webData = res['webData'];
          this.brandName = res['brand']['name'];
          this.showButton = true;
          this.addonFeatureOn = true;
          this.showLanguage = false;
          this.calculateDistanceScreens(res['brand']['home_screen_section']);
          if (res['brand'].web_app_theme_colors) {
          this.changeThemeVariables(res['brand'].name, res['brand']?.app_theme_icons, res['brand']?.fav_icon, res['brand'].web_app_theme_colors);
          }
          if(res['brand']?.show_currency && res['brand']?.show_currency == "symbol"){
            this.currencyType = 'symbol';
            this.currency = res['brand']?.currency;
          } else {
            this.currencyType = 'abbreviation';
            this.currency = (res['brand']?.currency_abbr).trim();
          }
          this.getLatlng();
        }
        });
    });

    this.lang.subscribe(value => {
      this.language = value;
    });
    const langData = '&lang='+this.language;

    this.httpService.post(this.storeId+'/'+this.baseUrl+'homescreen/webPagesSectionContents', langData).subscribe((res) => {
        if (res['success']) {
          this.pagesData = res['data'];
        }
    });
     this.httpService.get(this.storeId+'/'+this.baseUrl+'homescreen/webMenuSetting').subscribe((res) => {
        if (res['success']) {
          this.menuDataAccessed.next(res['data']);
          this.menuData = res['data'];
        }
    });
  }
   
   changeThemeColor(themeColor) {
    $('#theme-variable').html('');
    const variableCSS = ':root {--primary-bg-color: ' + themeColor.web_theme_primary_color + '; --secondary-color : ' + themeColor.web_theme_secondary_color + ';--open-cat-color : ' + themeColor.web_theme_category_open_color + '; --stripstopbottombg :'+themeColor.strips_color+'; --footerbg : '+themeColor.footer_color+'; --mainbg : '+themeColor?.listing_background_color+'; --buttonborderbg : '+themeColor.category_listing_button_border_color+'; --sepboxborderbg : '+themeColor.listing_border_color+'; --threeboxesbg : '+themeColor.listing_box_background_color+'; --subheading : '+themeColor.home_sub_heading_color +'; --description : '+themeColor.home_description_color+'; --menuBarColor : '+themeColor.menu_background_color+';--menuBarTextColor : '+themeColor.menu_font_color+';--topBarTextColor : '+themeColor.topbar_font_color+';}';
    $('#theme-variable').html(variableCSS);
  }

  changeThemeVariables(storeName, appicons, favIcon, themeColor) {
    this.storeTheme = themeColor.web_theme_primary_color;
    this.changeManifest(storeName, appicons, themeColor.web_theme_primary_color);
    this.changeThemeColor(themeColor);
    $('.web-favicon').attr('href', favIcon);
  }


  convertStringInStoreId(string)
  {
    var pieces = string.split("-"); 
    return pieces[pieces.length-1];
  }
  

   changeFbPixel(pixelID: string) {
    const faScript = document.createElement('script');
    faScript.innerText = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq(\'init\', \'${pixelID}\');fbq('track', 'PageView');`;

    const faScript2 = document.createElement('noscript');
    faScript2.innerText = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${pixelID}&ev=PageView&noscript=1"/>`;
    document.documentElement.firstChild.appendChild(faScript);
    document.documentElement.firstChild.appendChild(faScript2);
  }

  changeGaCode(trackingID: string) {
    const gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${trackingID}`);

    const gaScript2 = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${trackingID}\');`;

    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);
  }

  changeManifest(storeName, appicons, storeTheme)
  {
     var myDynamicManifest = {
        "name": storeName,
        "short_name": storeName,
        "description": storeName,
        "scope": "./",
        "start_url": "./",
        "background_color": storeTheme,
        "theme_color": storeTheme,
        "display": "standalone",
        "icons": [
          {
              "src": appicons?.icon_72_72,
              "sizes": "72x72",
              "type": "image/png",
              "purpose": "maskable any"
          },
          {
            "src": appicons?.icon_96_96,
            "sizes": "96x96",
            "type": "image/png",
            "purpose": "maskable any"
          },
          {
            "src": appicons?.icon_128_128,
            "sizes": "128x128",
            "type": "image/png",
            "purpose": "maskable any"
          },
          {
            "src": appicons?.icon_144_144,
            "sizes": "144x144",
            "type": "image/png",
            "purpose": "maskable any"
          },
          {
            "src": appicons?.icon_152_152,
            "sizes": "152x152",
            "type": "image/png",
            "purpose": "maskable any"
          },
          {
            "src": appicons?.icon_192_192,
            "sizes": "192x192",
            "type": "image/png",
            "purpose": "maskable any"
          },
          {
            "src": appicons?.icon_384_384,
            "sizes": "384x384",
            "type": "image/png",
            "purpose": "maskable any"
          },
          {
            "src": appicons?.icon_512_512,
            "sizes": "512x512",
            "type": "image/png",
            "purpose": "maskable any"
          }
        ]
      }
      const stringManifest = JSON.stringify(myDynamicManifest);
      const blob = new Blob([stringManifest], {type: 'application/json'});
      const manifestURL = URL.createObjectURL(blob);
      document.querySelector('#custom-manifest').setAttribute('href', manifestURL);
      /*CHANGING APPLE ICON*/
      document.querySelector("#apple-app-icon").setAttribute('href', appicons?.icon_192_192);
  }


  getCategories() {
    this.lang.subscribe(value => {
      this.language = value;
    });
    const langData = '&lang='+this.language+'&operating_zone_id='+(this.locationId ? this.locationId : '');
    this.httpService.post(this.storeId+'/'+this.baseUrl+'homescreen/getCategories', langData).subscribe((res) => {
      if (res['success']) {
        this.categories = res['data'];
        this.categories$.next(res['data']);
      }
    });
  }
   
  getSearchByKeyword(data, storeId):Observable<any>{
    return this.httpService.post(this.storeId+'/v1/'+storeId+'/inventory/searchProducts', data);
  }

  getLocations(): Observable<any> {
    return this.httpService.get(this.storeId + '/' + this.baseUrl + 'homescreen/brand_operating_zones');
  }

  getItems(): Observable<any[]> {
    return this.categories$.asObservable();
  }
}