import { Component, OnInit,Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';
import { CartItem } from '../../../../shared/classes/cart-item';
import { CartService } from '../../../../shared/services/cart.service';
import { LandingFixService } from 'src/app/shared/services/landing-fix.service';
import { Router } from '@angular/router';
import { CookieService } from "ngx-cookie-service";
import { PagesService } from 'src/app/pages/pages.service';
import { ToastrService } from 'ngx-toastr';
import { Menu } from './navbar-items';
import { Subscription } from 'rxjs';
import { ShareService } from '@ngx-share/core';
import { SocialAuthService } from "angularx-social-login";
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {
  @Input() shoppingCartItems  :   CartItem[] = [];
  public isLoggedIn : boolean = false;
  @Output() valueChange = new EventEmitter();
  public username : any;
  referApiHit = false;
  referMessage = '';
  public menuItems: Menu[];
  private subscriptions: Subscription[] = [];
  socialExists : boolean = false;
  socialValues : any;
  storeData : any;
  currentlang: any;
  public deviceData = "device_id=1111&device_token=111&platform=web";
  walletAmount : any = 0;
  constructor(private fix: LandingFixService,private cdr: ChangeDetectorRef, public share: ShareService, private cs: CookieService, public pagesService: PagesService, public baseService: BaseService,private router: Router, private cartService: CartService,public toastrService:ToastrService, private socialAuthService: SocialAuthService,private translate: TranslateService) { }

  ngOnInit() {  
    //this.getSocialLinks();
    var modal = document.getElementById("shareLink");
    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    }

   this.subscriptions.push(
      this.baseService.currentStoreData.subscribe((value) => {
        if (value) {
          this.storeData = value;
          if(this.storeData) {
            this.setMenuItems();
            this.getUserWalletDetails();
          }
          // if(value['wallet_setting'] && value['wallet_setting'] == '1')
          // {              
          //   this.storeId = value['id'];
          //   this.wallet = true;              
          //   this.getUserWalletDetails();
          // }
        }
      })
    );


    let csData = this.cs.get('UserInfo');
    //let csData = localStorage.getItem('UserInfo');    
    if (csData) {
      this.isLoggedIn = true;
      let encodedData = JSON.parse(csData);
      this.username   = encodedData['full_name'];
    }
  }


  setMenuItems()
  {
    this.subscriptions.push(
      this.baseService.getItems().subscribe(items => {
        const menuitems_ = [
          { path: '/' + this.baseService?.storeType + '/home', title: 'home', type: 'link', icon: './assets/images/left-menu/home.png' },
          { path: '/' + this.baseService?.storeType + '/dashboard', title: 'my-profile', type: 'link', icon: './assets/images/left-menu/profile.png' },
          { path: '/' + this.baseService?.storeType + '/orders', title: 'my-orders', type: 'link', icon: './assets/images/left-menu/order.png' },
           { path: '/' + this.baseService?.storeType + '/loyality', title: 'loyality-points', type: 'link', icon: './assets/images/left-menu/loyality.png' },
           { path: '/' + this.baseService?.storeType + '/cart', title: 'my-cart', type: 'link', icon: './assets/images/left-menu/cart.png' },
           {
            path: '',
            title: (this.pagesService?.userInfo && this.storeData?.is_referer_fn_enable == true) ? 'refer-earn' : 'share',
            type: 'click',
            icon: './assets/images/left-menu/share.png',
            clickEvent: 'shareUrl'
          },
          { path: '', type: 'separator' },
          { path: '/' + this.baseService?.storeType + '/about-us', title: this.baseService?.pagesData?.homepage?.about_heading, type: 'link', icon: './assets/images/left-menu/about.png' },
          { path: '/' + this.baseService?.storeType + '/contact', title: this.baseService?.pagesData?.homepage?.footer_contact_us, type: 'link', icon: './assets/images/left-menu/contact.png' },
          { path: '/' + this.baseService?.storeType + '/partners',
          title: 'merchant-registration', 
          type: 'link',
           icon: './assets/images/left-menu/merchant.png' },
          { path: '/' + this.baseService?.storeType + '/runners', title: 'Deliver with Afro Yum', type: 'link', icon: './assets/images/left-menu/runner.png' },
           { path: '/' + this.baseService?.storeType + '/terms', title: 'terms-conditions', type: 'link', icon: './assets/images/left-menu/Terms.png' },
           { path: '/' + this.baseService?.storeType + '/privacy', title: 'privacy-policy' , type: 'link', icon: './assets/images/left-menu/privacy.png' },
           { path: '/' + this.baseService?.storeType + '/shipping', title: 'shipping-policy' , type: 'link', icon: './assets/images/left-menu/shipping.png' },
           { path: '/' + this.baseService?.storeType + '/refund', title: 'refund-policy', type: 'link', icon: './assets/images/left-menu/refund.png' },
           { path: '/' + this.baseService?.storeType + '/faq', title: 'faq', type: 'link', icon: './assets/images/left-menu/faq.png' },
           
        ];
         let selectLang = {path:'', title : 'select-language', class: 'select-lang',
         icon: './assets/images/left-menu/language.png' ,
         type : 'sub', children: this.storeData?.languages?.map(category => {
                return {
                  path: '',
                  title: category.name.toLowerCase(),
                  type: 'click',
                  clickEvent: 'changeLanguage',
                  class: category.language,
                  direction : category.direction,
                };
              })
            };
            
          if(this.storeData?.languages?.length) {
            //console.log("aau");
            menuitems_.splice(0, 0, selectLang);
          }
          //console.log("sss", menuitems_);
        this.menuItems = [...menuitems_];
      })
    ); 
  }

  menuClickEvent(event, shortcode = '', title = '', path = '') {
    this.toggleMenu();
    if (event === 'shareUrl') {
      this.shareUrl();
    }
    if(event === 'changeLanguage')
    {
      this.changeLanguage(shortcode, title, path);
    }

  }

  getUserWalletDetails()
  {
     this.pagesService.getUserWalletDetails();
     this.pagesService.walletData.pipe(first()).subscribe(value => {
          if (value) {
            this.walletAmount = (value['user_wallet']*1).toFixed(2);
          }
        });
  }

  public changeLanguage(lang, currentlang, dir){
    this.currentlang = currentlang;
    this.translate.use(lang);
    this.baseService.language = lang;
    this.baseService.lang.next(lang);
    $("body").removeClass("ltr rtl LTR RTL");
    $("html").attr("dir", dir);
    $("body").addClass(dir);
    if(this.router.url == "/")
    {
      this.baseService.storeDetails(this.deviceData);
      this.baseService.getCategories();
    }
  }


   clean(obj) {
    for (var propName in obj) { 
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
        delete obj[propName];
      }
    }
    return obj;
  }

   getSocialLinks()
  {
    const socialData = 'device_id=1111&device_token=111&platform=web';
     this.subscriptions.push(
       this.pagesService.getAllSocials(socialData).subscribe((res) => {
          if (res['success']) { 
            const data = res['data'];
            delete data.id; //unsetting id 
            const socialValues = this.clean(data);
            if(Object.keys(socialValues).length === 0)
            {
              this.socialExists = false;
            } else
            {
              this.socialValues = socialValues;
              this.socialExists = true
            }
          }else{
            this.toastrService.error(res['message'], 'Error!');
          }
        })
       );
  }


  closeShareModal(){
    document.getElementById("shareLink").style.display = "none";
  }


  toggleMenu()
  {
    $(".search-overlay").css("display","none");
    this.fix.removeNavFix();
  }

  ngOnDestroy() {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

  shareUrl(){
    this.toggleMenu();
    if(this.pagesService.userInfo || !this.baseService?.storeData?.is_referer_fn_enable){
      $('#refererAlertPopup').modal('hide');
      const showLoader = true;
      this.valueChange.emit(showLoader);
    }else{
      document.getElementById("shareLink").style.display = "none";
      $('#refererAlertPopup').modal('show');
    }
  }
  
  displayCopied(){
    this.toastrService.success('Copied to clipboard', 'Success!')
  }

  goForLogin(){
    $('#refererAlertPopup').modal('hide');
    this.router.navigate(['/'+this.baseService?.storeType+'/login'])
  }
  
   

  logout()
  {
    document.getElementById("shareLink").style.display = "none";
    this.pagesService.userInfo = null;
    this.cartService.clearCart();
    this.socialAuthService.signOut().then().catch(err => { console.log(err); });
    //localStorage.removeItem('UserInfo');
    this.cs.delete('UserInfo', '/');
    this.cs.delete('deliveryTimeSlot', '/');
    this.cs.delete('displayDeliveryTimeSlot', '/');
    this.cs.delete('selectedAddress', '/');
    this.cs.delete('selectedAddIndex', '/');
    this.cs.delete('orderType', '/');
    this.router.navigate(['/'+this.baseService?.storeType+'/home']);
    this.toggleMenu();
  }

  // Get sub-cat products
  getSubCatProducts(category){
    this.router.navigate(['/collection/'+(((category.title.replace(/[^&a-zA-Z ]/g,'-')).replace(/ /g,"-")).toLowerCase())+'/'+category.sub_category[0].id]);
    this.toggleMenu();
  }

}
