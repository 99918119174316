<!-- Search -->
<div id="search-overlay" class="search-overlay"  [ngStyle]="{ 'display': 'none' }">
    <div>
       <div class="overlay-content">
          <span class="closebtn" title="Close Overlay" (click)="closeSearch()" *ngIf="baseService.isMobile">×</span>
          <div class="container-fluid">
             <div class="row">
                <div class="col-xl-12 search_outer_area">
                   <div class="filter-wrapper search-block">
                      <div class="keyword-wrapper">
                         <div class="search-results">
                            <input (keyup)="onKeySearch($event)" (keydown.enter)="onKeySearch($event)" type="text" id="keyword" autocomplete="off"  placeholder="{{ (baseService?.storeData?.type == 'restaurant' ? ('search-for-restaurants' | translate) : ('search-for-products' | translate))}}" #keywordsearch>
                            <button name="searchrestos" (click)="getResults()" class="search-icon"><i class="fa fa-search"></i></button>
                         </div>
                         <div class="search-restaurants-dishes" *ngIf="isMenuOpen">
                            <div class="loader_center text-center" *ngIf="apiHit">
                               <img src="assets/images/loader.gif" alt="loading">
                            </div>
                            <div class="no_data_found text-center" *ngIf="!apiHit && !restaurants.length && !dishesList.length && closeFlag == false">
                               <h4>{{'no-data-found' | translate}} !</h4>
                            </div>
                            <div class="restaurants-container" *ngIf="restaurants && restaurants.length;">
                               <div class="restaurants-title">
                                  <h3 [innerHTML]="baseService?.storeData?.type == 'restaurant' ? 'Restaurants' : 'Stores'"></h3>   
                                  <!-- <a [routerLink]="['restaurants/search/all']" [innerHTML]="baseService?.storeData?.type == 'restaurant' ? ('view-all-restaurants' | translate) : ('view-all-stores' | translate)"></a> -->
                                  </div>
                               <ul>
                                  <li *ngFor="let resto of restaurants  | slice: 0 : 2;" class="search-store-closed ">
                                     <div class="restro-img-wrapper" [ngClass]="{'store_timing' : resto?.storeOpen == false}">
                                        <div class="store_closed" *ngIf= "resto.storeOpen == false">
                                           <h5>{{'store-closed' | translate}}</h5>
                                        </div>
                                        <a [routerLink]="['/merchant/'+resto?.slug]"><img [defaultImage]="defaultImage" [lazyLoad]="resto?.image" alt="{{resto?.store_name}}"></a>
                                     </div>
                                     <div class="restro_detail" (click)="closeSearch()">
                                        <a [routerLink]="['/merchant/'+resto?.slug]">
                                           <h4 *ngIf="resto?.store_name">{{resto?.store_name}}</h4>
                                        </a>
                                        <p *ngIf="resto?.location">{{resto?.location.toLowerCase()}}</p>
                                        <p *ngIf="resto?.preparation_time" class="prep_time">{{'delivery-in' | translate}} {{resto?.preparation_time}} {{'mins' | translate}}</p>
                                     </div>
                                  </li>
                               </ul>
                               <!-- <div class="all_outlets"><a [routerLink]="['restaurants/search/all']" [innerHTML]="baseService?.storeData?.type == 'restaurant' ? ('view-all-outlets' | translate) : ('view-all-stores' | translate)" class="outlet"></a></div> -->
                            </div>
                            <div class="dishes-container" *ngIf="dishesList && dishesList.length">
                               <div class="dishes-title">
                               <h3 [routerLink]="['stores/search/all']" [innerHTML]="baseService?.storeData?.type == 'restaurant' ? 'Dish available in restaurants' : 'Product Found in Stores'"></h3>
                               <!-- <h3 [routerLink]="['restaurants/search/all']" [innerHTML]="baseService?.storeData?.type == 'restaurant' ? ('dishes' | translate) : ('products' | translate)"></h3> -->
                                  </div>
                               <ul *ngIf="dishesList && dishesList.length > 0">
                                  <li *ngFor="let dish of dishesList  | slice: 0 : 2;">
                                     <!-- <a (click)="closeSearch()" [routerLink]="['/collection', dish?.store_id]" [queryParams]="{search: searchKeyword}"> -->
                                       <a (click)="closeSearch()" [routerLink]="['/merchant/'+dish?.slug]" [queryParams]="{search: searchKeyword}">
                                     <img *ngIf="dish?.image_100_80" [lazyLoad]="dish?.image_100_80" alt="{{dish.store_name}}">
                                     <img *ngIf="!dish?.image_100_80" [src]="defaultImage" alt="{{dish.store_name}}"  width="70" height="70">
                                     </a>
                                     <div class="dish_detail" (click)="closeSearch()">
                                        <!-- <a [routerLink]="['/collection', dish?.store_id]" [queryParams]="{search: searchKeyword}"> -->
                                        <a [routerLink]="['/merchant/'+dish?.slug]" [queryParams]="{search: searchKeyword}">
                                        <h4 *ngIf="dish?.title">{{dish?.title}}</h4>
                                        </a>
                                        <p *ngIf="dish?.sub_category">{{dish?.sub_category}}</p>
                                        <p *ngIf="dish?.store_name">{{dish?.store_name}}</p>
                                     </div>
                                  </li>
                               </ul>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </div>