import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { ProductsService } from '../shared/services/products.service';
import { CartService } from '../shared/services/cart.service';
import { BaseService } from '../shared/services/base.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Location } from "@angular/common";
declare var $: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  providers: [ProductsService, CartService]
})
export class MainComponent implements OnInit {
  public storeData;
  public url: any;
  public loadAll: boolean = false;
  public givemargin: boolean = false;
  public partnersPage: boolean = false;
  public runnerPage: boolean = false;
  private subscriptions: Subscription[] = [];
  private footerImage: any;
  collectionPage: boolean = false;
  constructor(private route: ActivatedRoute, private router: Router, public baseService: BaseService, public toastrService: ToastrService, location: Location) {
    this.subscriptions.push(
      this.baseService.currentStoreData.subscribe((value) => {
        if (value) {
          this.storeData = value;
          //this.router.changes.subscribe((val) => console.log(val))
          this.router.events.subscribe((event) => {
            // let pdata = event.snapshot.data;
            // console.log("pdata", pdata);
            // if(event.snapshot.data['pagename'] == "collection")
            // {
            //    console.log(event.snapshot.data['pagename'], "eventott");
            //    this.collectionPage = true;
            // }
            if (event instanceof NavigationEnd) {

              this.url = event.url;
              if (location.path() != "") {
                this.givemargin = false;
              } else {
                this.givemargin = true;
              }
              if (this.url.indexOf('partners') > -1) {
                this.partnersPage = true;
              } else {
                this.partnersPage = false;
              }
              if (this.url.indexOf('runners') > -1) {
                this.runnerPage = true;
              } else {
                this.runnerPage = false;
              }
            }
          });
        }
      })
    );


  }

  ngOnInit() {
    // console.log("collc", this.collectionPage);
    // this.route.params.subscribe((params: Params) => {
    //   console.log("params", params);
    // }); 

    //$.getScript('assets/js/script.js');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth >= 991) {
      this.baseService.isDesktopDevice = true;
      this.baseService.isMobile = false;
      this.baseService.device = "web";
    } else {
      this.baseService.isDesktopDevice = false;
      this.baseService.isMobile = true;
      this.baseService.device = "pwa";
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit() {
    $('.loader-wrapper-skeleton').remove();
  }
}