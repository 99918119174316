import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../shared/services/http.service';
import { BaseService } from '../shared/services/base.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class PagesService {
	private baseUrl = 'v1/';
  private storeID : number;
  public userInfo = null;
  @Output() walletData = new EventEmitter<any>();
  constructor(private httpService: HttpService, public _cs: CookieService,public baseService:BaseService) {
    this.userInfo = this._cs.get('UserInfo') ? JSON.parse(this._cs.get('UserInfo')) : null;
    //this.userInfo = localStorage.getItem('UserInfo') ? JSON.parse(localStorage.getItem('UserInfo')) : null;
  }

  userLogin(data):Observable<any>{
    return this.httpService.post(this.baseService.storeId+'/'+this.baseUrl+'user_authentication/userLogin', data);
  }

  userSignup(data):Observable<any>{
    return this.httpService.post(this.baseService.storeId+'/'+this.baseUrl+'user_authentication/userSignup', data);
  }

  getReferDetails(data):Observable<any>{
    return this.httpService.post(this.baseService.storeId+'/'+this.baseUrl+'user_authentication/getReferDetails', data);
  }

  verifymobileNo(data):Observable<any>{
    return this.httpService.post(this.baseService.storeId+'/'+this.baseUrl+'user_authentication/mobileVerification', data);
  }

  verifyMobileOtp(data):Observable<any>{
    return this.httpService.post(this.baseService.storeId+'/'+this.baseUrl+'user_authentication/verifyOtp', data);
  }

  getAllSocials(data):Observable<any>{
    return this.httpService.post(this.baseService.storeId+'/'+this.baseUrl+'marketplace/homescreen/social_linking', data);
  }
  
  getStoreAreas(data):Observable<any>{
    return this.httpService.post(this.baseService.storeId+'/'+this.baseUrl+'storearea', data);
  }

  forgetPassword(data):Observable<any>{
    return this.httpService.post(this.baseService.storeId+'/'+this.baseUrl+'user_authentication/forgetPassword', data);
  }

  sendPartnersEnquiry(data):Observable<any>{
    return this.httpService.post(this.baseService.storeId+'/'+this.baseUrl+'static_pages/partnerRequests', data);
  }


  sendRunnersEnquiry(data):Observable<any>{
    return this.httpService.post(this.baseService.storeId+'/'+this.baseUrl+'static_pages/runnerRequests', data);
  }

  getUserAdresses(data):Observable<any>{
    return this.httpService.post(this.baseService.storeId+'/'+this.baseUrl+'delivery_zones/deliveryAddress', data);
  }

  getUserOrders(data, storeId):Observable<any>{
    return this.httpService.post(this.baseService.storeId+'/'+this.baseUrl+storeId+'/orders/orderHistory', data);
  }

   getRestaurantsList(data):Observable<any>{
    return this.httpService.post(this.baseService.storeId+'/'+this.baseUrl+'marketplace/stores', data);
  }

  getBanners(data):Observable<any>{
    return this.httpService.post(this.baseService.storeId+'/'+this.baseUrl+'marketplace/homescreen/banners', data);
  }

   postReview(data, storeId):Observable<any>{
    return this.httpService.postWithFormData(this.baseService.storeId+'/'+this.baseUrl+storeId+'/review_rating', data);
  }

  orderDetails(data,storeId):Observable<any>{
    return this.httpService.post(this.baseService.storeId+'/'+this.baseUrl+storeId+'/orders/orderDetailHistory', data);
  }

  getCurrentOrders(data):Observable<any>{
    return this.httpService.post(this.baseService.storeId+'/'+this.baseUrl+'getOnTheWayOrders', data);
  }

  updateUserProfile(data):Observable<any>{
    return this.httpService.post(this.baseService.storeId+'/'+this.baseUrl+'user_authentication/updateProfile', data);
  }

  sendEnquiry(data):Observable<any>{
    return this.httpService.post(this.baseService.storeId+'/'+this.baseUrl+'static_pages/setStoreQuery', data);
  }
  
  cancelOrder(data, storeId):Observable<any>{
    return this.httpService.post(this.baseService.storeId+'/'+this.baseUrl+storeId+'/orders/orderCancel', data);
  }

   updateUserSocialProfile(data):Observable<any>{
    return this.httpService.post(this.baseService.storeId+'/'+this.baseUrl+'user_authentication/socialLogin', data);
  }

  verifySocialEmail(data):Observable<any>{
    return this.httpService.post(this.baseService.storeId+'/'+this.baseUrl+'user_authentication/verifyEmail', data);
  }

  userWallet(data):Observable<any>{
    return this.httpService.post(this.baseService.storeId+'/'+this.baseUrl+'marketplace/homescreen/user_wallet', data);
  }

  getUserWalletDetails()
  { 
    const storeidnumber = this.baseService?.storeId;
    const user_id = this.userInfo?.id;
    if(storeidnumber && user_id) {
      const walletData = 'user_id='+user_id+'&brand_id='+storeidnumber;        
      this.userWallet(walletData).subscribe((res) => {
        if(res['success']) {
          // this.userWalletData.next(res['data']); 
          this.walletData.emit(res['data']); 
          return true;          
        }
      });
    }
  }

  
}
