import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule  } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { ToastrModule } from 'ngx-toastr';
import { rootRouterConfig } from './app.routes';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HttpHeaders} from '@angular/common/http';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AgmCoreModule, LazyMapsAPILoaderConfigLiteral, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { BaseService } from './shared/services/base.service';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { map } from 'rxjs/operators';



// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
   return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function agmConfigFactory(http: HttpClient, config: LazyMapsAPILoaderConfigLiteral) {
  const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
  var hostData: string = '';
  var url: string = '';

  if(window.location.host == 'localhost:4200' || window.location.host == 'dev.afroyum.com') {

     url = 'https://devadmin.afroyum.com/';
     const hostName =  'dev.afroyum.com';
     hostData = "host=" + hostName;
  } 
  
  else {
     hostData = "host="+window.location.host;
     url = environment.base_url;
  }

  return () => http.post<any>(url + 'settings/checkDomainName', hostData, { headers: headers }).pipe(
      map(response => {
          config.apiKey = response.api_key;
          return response;
      })
  ).toPromise();
}


@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
  ],
  imports: [
    BrowserModule,
    HttpModule,
    BrowserAnimationsModule,
    SocialLoginModule,
    SharedModule,
    HttpClientModule,
    NgbModule,
    AgmCoreModule.forRoot({apiKey: '', libraries: ['places']}),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    ToastrModule.forRoot({
      timeOut: 5000,
      progressBar: true,
      enableHtml: true,
      extendedTimeOut:5000,
      preventDuplicates: true
    }),
    RouterModule.forRoot(rootRouterConfig, { useHash: false, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    BaseService,
    {
      provide: APP_INITIALIZER,
      useFactory: agmConfigFactory,
      deps: [HttpClient, LAZY_MAPS_API_CONFIG],
      multi: true
    }, 
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '656255905831-fkpjue1j5vkudfvf06pnejqqon31nl4d.apps.googleusercontent.com'
              // '633716083825-d0b9v5rskqv2pid3g2r68igkmj66kv12.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('3262024257231451')
          }
        ]
      } as SocialAuthServiceConfig
    }
    ],
  bootstrap: [AppComponent]
})
export class AppModule { 
}