<app-header-three [storeData]="storeData" [url]="url"></app-header-three>
<div class="main" (window:resize)="onResize($event)" [ngClass]="{
        'partners-page': partnersPage,
        'runners-page': runnerPage,
        'listing_main_header': (url.indexOf('restaurants') > -1)     
        
    }">
    <router-outlet></router-outlet>
</div>
<!-- 'listing_main_header': (url.indexOf('restaurants') > -1) 'listing_main_header': 'collectionPage' -->
<!-- 'main_margin': givemargin, <div *ngIf="collectionPage" class="listing-bg-image" [ngStyle]="{'background-image': 'url('+baseService?.storeData?.footer_banners[0]?.image+')'}"></div> -->
<app-footer-one [storeData]="storeData"></app-footer-one>