<router-outlet *ngIf="validStore"></router-outlet>
<div *ngIf="validStore" class="tap-top top-cls d-none d-lg-block">
    <div>
        <i class="fa fa-angle-double-up"></i>
    </div>
</div>
<div *ngIf="validStore && baseService?.storeData?.home_page_display_number_type && baseService?.storeData?.home_page_display_number_type == '0' && baseService?.storeData?.home_page_display_number != ''"
    class="d-none d-lg-block">
    <a href="https://wa.me/{{baseService?.storeData?.home_page_display_number}}" target="_blank"
        class="whatsapp-floater ">
        <!-- <i class="fa fa-whatsapp"></i> -->
        <img src="assets/images/whatsapp.png" alt="">
    </a>
</div>
<div *ngIf="validStore && baseService?.storeData?.home_page_display_number_type && baseService?.storeData?.home_page_display_number_type == '1' && baseService?.storeData?.home_page_display_number != ''"
    class="d-none d-lg-block">
    <a  href="tel:{{baseService?.storeData?.home_page_display_number}}" target="_blank"
        class="whatsapp-floater-call"><i class="fa fa-phone sidebar-bar"></i></a>
</div>
<div *ngIf="!validStore" class="error_msg"> 
    <div class="error_in">
    <h1>{{'error' | translate}}</h1>
    <h3>{{'error-code' | translate}} : 403</h3>
    <h3>{{'invalid-store-domain' | translate}}!</h3>
   </div>
</div>
