<div class="view_menu">
    <div class="col-lg-12 col-sm-12 col-xs-12 centerAlign spinner_overlay" *ngIf="showloader">
       <img src="assets/images/loader.gif" alt="loading" />
    </div>
    <div class="filter-wrapper search-block">
       <div class="location-search-block" *ngIf="baseService?.isDesktopDevice || baseService?.isMobile">
          <input *ngIf="showLocationPopup === false" type="text" class="form-control serch_web" (keydown.enter)="$event.preventDefault()" placeholder ="{{formattedAddress}}" autocorrect="off" id="search" autocapitalize="off" spellcheck="off" type="text" #search>

          <input (click)="getLocationList()" *ngIf="showLocationPopup === true && baseService?.isMobile" type="text" class="form-control serch_web" placeholder ="{{locationName}}" autocorrect="off" id="locationPopup" autocapitalize="off" spellcheck="off" type="text">
          
          <div (click)="getLocationList()" class="dropdown" *ngIf="baseService?.isDesktopDevice && baseService?.storeData?.is_loc_popup_enabled === '1' && hideLocationDropDown === false">
            <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{locationName}}
            </button>
               <div *ngIf="locationList" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                 <a *ngFor="let singleZone of locationList" class="dropdown-item" (click)="getStoreData(singleZone?.OperatingZone?.id, singleZone?.OperatingZone?.name)" [ngClass]="singleZone?.OperatingZone?.id == locationId ? 'checked' : ''"><i *ngIf="singleZone?.OperatingZone?.id == locationId" class="fa fa-check" aria-hidden="true"></i>{{singleZone?.OperatingZone?.name}}</a>
               </div>
          </div>
          
          <i class="fa fa-location-arrow locateme" aria-hidden="true" *ngIf="baseService?.isDesktopDevice && baseService?.storeData?.is_loc_popup_enabled !== '1'"></i>
          <!-- <i class="fa fa-caret-down locateme" aria-hidden="true" *ngIf="baseService?.storeData?.is_loc_popup_enabled !== '1' || baseService?.isMobile"></i> -->
       </div>
       <div class="keyword-wrapper" *ngIf="baseService?.isDesktopDevice">
          <div class="search-results" >
             <input (keyup)="onKeySearch($event)" (keydown.enter)="$event.preventDefault()" type="text" id="keyword" autocomplete="off"  placeholder="{{baseService?.pagesData?.homepage?.product_search_placeholder}}" #keyword>
             <button name="searchrestos" (click)="$event.preventDefault()" class="search-icon"><i class="ti-search"></i></button>
             <!-- <button name="searchrestos" (click)="getResults()" class="search-icon"><i class="fa fa-search"></i></button> -->
          </div>
          <div class="search-restaurants-dishes" *ngIf="isMenuOpen || apiHit">
             <div class="loader_center text-center" *ngIf="apiHit">
                <img src="assets/images/loader.gif" alt="loading">
             </div>
             <div class="no_data_found text-center" *ngIf="!apiHit && !restaurants.length && !dishesList.length">
                <h4>Item selected not available</h4>
             </div>
             <div class="restaurants-container" *ngIf="(restaurants && restaurants.length)">
                <div class="restaurants-title">
                   <h3 [innerHTML]="baseService?.storeData?.type == 'restaurant' ? ('restaurants' | translate) : ('stores' | translate)"></h3>
                   <!-- <a [routerLink]="['restaurants/search/all']" [innerHTML]="baseService?.storeData?.type == 'restaurant' ? ('view-all-restaurants' | translate) : ('view-all-stores' | translate)"></a> -->
                </div>
                <ul>
                   <li *ngFor="let resto of restaurants  | slice: 0 : 2;" class="search-store-closed">
                      <div class="restro-img-wrapper" [ngClass]="{'store_timing' : resto?.storeOpen == false}">
                         <a [routerLink]="['/merchant/'+resto?.slug]"><img [defaultImage]="defaultImage" [lazyLoad]="resto?.image" alt="{{resto?.store_name}}" ></a>
                      </div>
                   <!-- <li *ngFor="let resto of restaurants  | slice: 0 : 2;">
                      <a [routerLink]="['/collection', resto?.id]">
                      <img [defaultImage]="defaultImage" [lazyLoad]="resto?.image" alt="{{resto?.store_name}}" ></a> -->

                      <div class="restro_detail">
                         <a [routerLink]="['/merchant/'+resto?.slug]">
                         <!-- <a [routerLink]="['/merchant/'+resto?.slug]" [queryParams]="{search: searchKeyword}"> -->

                         <h4 *ngIf="resto?.store_name">{{resto?.store_name}}</h4>
                         </a>
                         <p *ngIf="resto?.location">{{resto?.location.toLowerCase()}}</p>
                         <p *ngIf="resto?.preparation_time && resto?.preparation_time > 1" class="prep_time">{{'delivery-in' | translate}} {{resto?.preparation_time}}</p>
                      </div>
                      <div class="store_closed" *ngIf= "resto.storeOpen == false">
                         <h5>{{'store-closed' | translate}}</h5>
                      </div>
                   </li>
                </ul>
                <!-- <div class="all_outlets"><a [routerLink]="['restaurants/search/all']" [innerHTML]="baseService?.storeData?.type == 'restaurant' ? ('view-all-outlets' | translate) : ('view-all-stores' | translate)" class="outlet"></a></div> -->
             </div>
             <div class="dishes-container" *ngIf="dishesList && dishesList.length && !restaurants.length">
                <div class="dishes-title">
                   <h3 [routerLink]="['merchant/search/all']" [innerHTML]="baseService?.storeData?.type == 'restaurant' ? 'Dish available in restaurants' : 'Product Found in Stores'"></h3>
                   <!-- <h3 [routerLink]="['stores/search/all']" [innerHTML]="baseService?.storeData?.type == 'restaurant' ? ('dishes' | translate) : ('products' | translate)"></h3> -->
                </div>
                <ul *ngIf="dishesList && dishesList.length">
                   <li *ngFor="let dish of dishesList  | slice: 0 : 2;">
                      <!-- <a [routerLink]="['/restaurants/'+dish?.slug]"> -->
                      <a [routerLink]="['/merchant/'+dish?.slug]" [queryParams]="{search: searchKeyword}">
                      <img *ngIf="dish?.image_100_80 ;else showdefaultimage" src="{{dish?.image_100_80}}" >
                      <ng-template #showdefaultimage> 
                         <img src="{{defaultImage}}" alt="" width="70" height="70">
                      </ng-template>
                      </a>
                      <div class="dish_detail">
                        <!--  <a [routerLink]="['/restaurants/'+dish?.slug]" > -->
                         <a [routerLink]="['/merchant/'+dish?.slug]" [queryParams]="{search: searchKeyword}">
                         <h4 *ngIf="dish?.title">{{dish?.title}}</h4>
                         </a>
                         <p class="sub-category" *ngIf="dish?.sub_category">{{dish?.sub_category}}</p>
                         <!-- <p class="store-name" *ngIf="dish?.store_name">{{dish?.store_name}}</p> -->
                         
                      </div>
                   </li>
                </ul>
             </div>
          </div>
       </div>
    </div>
 </div>
 <div class="modal fade" *ngIf="hidePopup === false" data-backdrop="static" data-keyboard="false" id="locationPopup2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div class="modal-dialog modal-dialog-centered" role="document">
     <div class="modal-content">
       <div class="modal-body">
           <div class="heading-location">
               <h4>List of available locations</h4>
           </div>
           <div class="city_data">
            <ul> 
               <li *ngFor="let singleZone of locationList">
                  <a (click)="getStoreData(singleZone?.OperatingZone?.id, singleZone?.OperatingZone?.name)" *ngIf="singleZone?.OperatingZone?.id && singleZone?.OperatingZone?.name" [ngClass]="singleZone?.OperatingZone?.id == locationId ? 'checked' : ''">
                     <i *ngIf="singleZone?.OperatingZone?.id == locationId" class="fa fa-check" aria-hidden="true"></i>
                     {{singleZone?.OperatingZone?.name}}
                  </a>
               </li>
            </ul>
         </div>    
         <div class="location-submit text-center">
            <button (click)="submitLocation()" type="button" class="btn">Submit</button>
        </div>   
       </div>
     </div>
   </div>
 </div>