import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CartItem } from '../classes/cart-item';
import swal, { SweetAlertOptions } from 'sweetalert2';
import { HttpService } from '../services/http.service';
import { BaseService } from '../services/base.service';
//let products = JSON.parse(localStorage.getItem("marketplaceCartItem")) || [];
@Injectable({
  providedIn: 'root'
})

export class CartService {
  private baseUrl = 'v1/';
  private subscriptions: Subscription[] = [];
  // new refactored code
  private readonly _cartItems = new BehaviorSubject<CartItem[]>(JSON.parse(localStorage.getItem('marketplaceCartItem')) || []);
  readonly cartItems$ = this._cartItems.asObservable();
  product: any;

  public incrementDecrementFlag: BehaviorSubject<string> = new BehaviorSubject('');
  public itemCount : BehaviorSubject<Number> = new BehaviorSubject(0);
 
  // public singleProduct: Subject<any> = new Subject();
  public isIncremented: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isRemoveItems: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public singleProduct: BehaviorSubject<[]> = new BehaviorSubject([]);
  public sendData: BehaviorSubject<[]> = new BehaviorSubject([]);
  singleProduct$: Observable<[]> = this.singleProduct.asObservable();
  public showMenu: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public updateQuantity: BehaviorSubject<string> = new BehaviorSubject('');
  isSameProduct: boolean;

  constructor(private toastrService: ToastrService, private httpService: HttpService,
    public baseService: BaseService) { 
    }

  get cartItems_(): CartItem[] { 
    return this._cartItems.getValue();
  }

  showMenuButton(data) {
    this.showMenu.next(data);
  }

  sendDataToPopup(data) {
    this.sendData.next(data);
  }

  newQuantity(data) {
    this.updateQuantity.next(data);
  }

  showremoveItemsPopup(flag) {
    this.isRemoveItems.next(flag);
  }

  incremented(flag) {
    this.isIncremented.next(flag);
  }

  incrementDecrement(flag) {
    this.incrementDecrementFlag.next(flag);
  }

  itemCounter(count) {
    this.itemCount.next(count);
  }

  singleProductFn(product) {
    this.singleProduct.next(product);
  }

  set cartItems_(val: CartItem[]) {
    localStorage.setItem('marketplaceCartItem', JSON.stringify(val));
    this._cartItems.next(val);
    //this.currentCartProducts.next(val);
  }

  /*
    {
      price: any, - total price with savings
      mrp: any, - mrp price actual
      savings: any - savings
    }
  */
  get total$(): Observable<{ price: any, mrp: any, savings: any }> {
    return this.cartItems$.pipe(
      map(items => {
        let price = 0;
        let mrp = 0;
        let savings = 0;
        items.map(item => {
          if(item?.product?.is_customizable == 1) {
            price += (parseFloat(item.product.main_variant_price) + item.product.addon_disc_total).toFixed(2) * item.quantity;
            mrp += (parseFloat(item.product.mrp_price) + item.product.addon_mrp_total).toFixed(2) * item.quantity;
            savings += ((item.product.addon_mrp_total - item.product.addon_disc_total) + (parseFloat(item.product.mrp_price) - parseFloat(item.product.main_variant_price))) * item.quantity;
          } 
          else if(item?.product?.is_customizable != 1) {
            price += (item.product.price) * item.quantity;
            mrp += (item.product.mrp_price) * item.quantity;
            savings += (item.product.mrp_price - item.product.price) * item.quantity;
          }
        });
        return { price, mrp, savings };
      })
    );
  }

  addToCart(product, quantity: number) {
    this.product = product;    
    if (!this.calculateStockCounts(product, quantity)) {
      return;
    }

    let chkStatus = this.checkInStore(product);
    if(chkStatus == false)
    {
      return;
    } 

    this.checkItem(product);
    
    if (!this.calculateStockCounts(product, quantity)) {
      return;
    }
    const idx = this.cartItems_.findIndex((item) => item.product.id === product.id && item.product.weight === product.weight);
    if (idx === -1) {
      this.cartItems_ = [
      { product: product, quantity: 1 },
        ...this.cartItems_        
      ];
    } else {
      this.cartItems_[idx] = {
        product,
        quantity: this.cartItems_[idx]['quantity'] += 1
      };
      this.cartItems_ = [
        ...this.cartItems_
      ];
    }
  }

  areEqual(cartItem, newItem) {
    if (cartItem.length == newItem.length) {
      return cartItem.every((element, index) => {
        if (element.id == newItem[index].id) {
          return true;
        }
        return false;
      });
    }
    return false;
  }

  addOnAddToCart(product, quantity: number) {
    this.product = product;  
    if (!this.calculateStockCounts(product, quantity)) {
      return;
    }

    let chkStatus = this.checkInStore(product);
    if(chkStatus == false)
    {
      return;
    } 

    this.checkItem(product);
    
    if (!this.calculateStockCounts(product, quantity)) {
      return;
    }
    const idx = this.cartItems_.findIndex(item => item.product.id === product.id && item.product.cart_index_id === product.cart_index_id && item.product.variantAdded === product.variantAdded && item.product.primary_product === product.primary_product && item.product.weight === product.weight
    );
    if(idx != -1) {
      this.cartItems_.filter(cartItem =>{
        if(cartItem.product.is_customizable == 1) {
          if(cartItem.product.variants_list.length > 0 && product.variants_list.length > 0 && cartItem.product.cart_index_id === product.cart_index_id) {
            this.isSameProduct = this.areEqual(cartItem.product.variants_list, product.variants_list);
          }
        }
      })
      if(this.isSameProduct == false) {
        this.cartItems_ = [
          { product: product, quantity: 1 },
            ...this.cartItems_        
          ];
      }
      if(this.isSameProduct == true || this.isSameProduct == undefined) {
        this.cartItems_[idx] = {
          product,
          quantity: this.cartItems_[idx]['quantity'] = quantity
        };  
        this.cartItems_ = [
          ...this.cartItems_
        ];
      }
    }
     else {
      this.cartItems_ = [
        { product: product, quantity: quantity },
          ...this.cartItems_        
        ];
      this.cartItems_ = [
        ...this.cartItems_
      ];
    }
  }


  checkItem(product) {
    const currentCartItems = this.cartItems_;
    if(currentCartItems.length == 0) {
      this.storeConfig(product.store_id).subscribe(res => {
        if (res['success']) {
          const storeData = {
            id: res['store'].id,
            store_name : res['store'].store_name,
            store_status: res['store'].store_status,
            pickup_facility: res['store'].pickup_facility,
            delivery_facility: res['store'].delivery_facility,
            delivery_slot: res['store'].delivery_slot,
            lat: res['store'].lat,
            lng: res['store'].lng,
            location: res['store'].location,
            city: res['store'].city,
            state : res['store'].state,
            country : res['store'].country,
            zipcode : res['store'].zipcode,
            slug : res['store'].slug,
            contact_email: res['store'].contact_email,
            contact_number: res['store'].contact_number,
            contact_person: res['store'].contact_person,
            online_payment: res['store'].online_payment,
            dine_in: res['store'].dine_in,
            //payment_gateway: res['store'].payment_gateway,
            is24x7_open: res['store'].is24x7_open,
            store_open_days: res['store'].store_open_days,
            openhours_from: res['store'].openhours_from,
            openhours_to: res['store'].openhours_to,
            //payment_setting: res['store'].payment_setting,
            delivery_area: res['store'].delivery_area
        }
          localStorage.setItem('grocersStoreDetails',JSON.stringify(storeData));
      }
      });
    }
  }


  checkInStore(product)
  {
   const lastItem = this.cartItems_[this.cartItems_.length - 1];
   if(!lastItem)
   {
    return true;
   }
    if((lastItem['product']['store_id'] == product['store_id']))
    {
      return true;
    } 
    else if((product.hasOwnProperty('product') && lastItem['product']['store_id'] == product['product']['store_id'])) {
      return true;
    }
    else
    {
        swal.fire({
        title: 'You have items from another store in cart.',
        text: "What do you want to do?",
        type: 'warning',
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonColor: 'red',
        // cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Empty Cart!',
        customClass: {
        confirmButton: 'confirm-button-class',
        cancelButton: 'cancel-button-class'
        },
        }).then((result) => {
          if (result.value) {
            this.clearCart();
          }
        });
     
      return false;
    }
  }

  removeCartItemAddOn(item: CartItem) {
    if(item.product.remove_id) {
      this.cartItems_ = this.cartItems_.filter(item_ => !(item_.product.remove_id === item.product.remove_id));
    } else {
      this.cartItems_ = this.cartItems_.filter(item_ => !(item_.product.id === item.product.id && item_.product.cart_index_id === item.product.cart_index_id && item_.product.variantAdded === item.product.variantAdded && item_.product.primary_product === item.product.primary_product && item_.product.weight === item.product.weight))
    }
  }


  removeCartItem(item: CartItem) {
    // tslint:disable-next-line: max-line-length
    this.cartItems_ = this.cartItems_.filter(item_ => !(item_.product.id === item.product.id && item_.product.weight === item.product.weight));
  }

  // @deprecated TODO: remove this, use above
  public removeFromCart(item: CartItem) {
    this.removeCartItem(item);
  }

  decreaseAddOnQuantity(product) {
    const idx = this.cartItems_.findIndex(item => item.product.id === product.id && item.product.cart_index_id === product.cart_index_id && item.product.variantAdded === product.variantAdded && item.product.primary_product === product.primary_product && item.product.weight === product.weight)
    if(idx != -1) {
      this.cartItems_.filter(cartItem =>{
        if( (cartItem.product.cart_index_id === product.cart_index_id)) {
          if(cartItem.product.variants_list.length && product.variants_list.length && (cartItem.product.cart_index_id === product.cart_index_id)) {
            this.isSameProduct = this.areEqual(cartItem.product.variants_list, product.variants_list);
          } 
            if(this.isSameProduct == undefined || this.isSameProduct == true) {
              if(this.cartItems_[idx]['quantity'] == 1) {
                this.removeCartItemAddOn(cartItem);
              } else {
                this.cartItems_[idx] = {
                  product: product,
                  quantity: this.cartItems_[idx]['quantity'] -= 1
                };
                this.cartItems_ = [
                  ...this.cartItems_
                ];
              }
            }
        }
      });
    }
  }

  dercreaseItemQuantity(item: CartItem) {    
    const idx = this.cartItems_.findIndex((item_) => item_.product.id === item.product.id && item_.product.variant_id === item.product.variant_id);
    if (idx === -1) {
      // this.cartItems_ = [
      //   ...this.cartItems_,
      //   { product: product, quantity: 1 }
      // ];
    } else {
      if (this.cartItems_[idx]['quantity'] <= 0) {
        this.removeCartItem(item);
      } else {
        this.cartItems_[idx] = {
          product: item.product,
          quantity: this.cartItems_[idx]['quantity'] -= 1
        };
        this.cartItems_ = [
          ...this.cartItems_
        ];
      }
    }
  }

  // Get Products
  // @deprecated. to be removed TODO: use abouve fx instead
  public getItems(): Observable<CartItem[]> {
    return this.cartItems$;
  }

  // Clear cart
  public clearCart() {
    this.cartItems_ = [];
  }

  public updateCart(product, quantity: number) {
    this.addToCart(product, quantity);
  }

  /* temporary system.TODO: optimise */
  // Calculate Product stock Counts
  public calculateStockCounts(product, quantity) {
    if (product.stock_type === 'continue_selling') {
      return true;
    }
    if (product.stock_type === 'threshold_quantity') {
      const productInCart = this.cartItems_.find((item_) => item_.product.id === product.id && item_.product.weight === product.weight);
      if (productInCart && product.stock <= productInCart.quantity) {
        this.toastrService.error('You can not add more items than available. In stock ' + product.stock + ' items.', 'Error!');
        return false;
      }
    }
    if (product.stock_type === 'min_alert') {
      const productInCart = this.cartItems_.find((item_) => item_.product.id === product.id && item_.product.weight === product.weight);
      if (productInCart && product.stock <= (productInCart.quantity + (product.min_stock_alert * 1)) ) {
        this.toastrService.error('You can not add more items than available. In stock ' + (product.stock - (product.min_stock_alert * 1)) + ' items.', 'Error!');
        return false;
      }
    }
    return true;
  }

  storeConfig(store_id):Observable<any>{
    return this.httpService.get(this.baseService.storeId+'/'+this.baseUrl+store_id+'/store_configuration');
  } 

  getSubCategoryProducts(subCatId, storeId, catData): Observable<any> {
    return this.httpService.post(this.baseService.storeId+'/'+this.baseUrl+storeId+'/inventory/getSubCategoryProducts/' + `${subCatId}` + '/1/'+400, catData);
  }  
}
