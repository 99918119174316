import { Component, OnInit, Input } from '@angular/core';
import * as $ from 'jquery';
import { BaseService } from 'src/app/shared/services/base.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  @Input() categories:any;
  constructor(public baseService: BaseService, public translate: TranslateService) { }
  
  // collapse toggle
  ngOnInit() {
  }

  toggleCatTab(i){
    var speed = 100;
    var thisItem = $('#blockContainer'+i),
      nextLevel = $('#blockContent'+i);
    if (thisItem.hasClass('open')) {
      thisItem.find(".collapse-block-title").removeClass('active');
      thisItem.find(".collapse-block-title").removeClass('arrow_up').addClass('arrow_bottom');
      thisItem.removeClass('open');
      nextLevel.slideUp(speed);
    } else {
      $( '.cat-block .open .collection-collapse-block-content' ).slideUp(speed);
      $( '.cat-block .open .collapse-block-title' ).removeClass('active');
      $( '.cat-block .open .collapse-block-title' ).removeClass('arrow_up').addClass('arrow_bottom');
      $( '.cat-block .open' ).removeClass('open');
      thisItem.addClass('open');
      thisItem.find(".collapse-block-title").addClass("active");
      thisItem.find(".collapse-block-title").removeClass('arrow_bottom').addClass('arrow_up');
      nextLevel.slideDown(speed);
    }
}


  // Modify Category Title
  modifyCatTitle(category){
    let modified = category.replace(/[^&a-zA-Z ]/g,'-')
    return (modified.replace(/ /g,"-")).toLowerCase();
  }

  // For mobile view
  public mobileFilterBack() {
     $('.collection-filter').css("left", "-100%");
  }

}
