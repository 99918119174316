import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LandingFixService } from '../../../../services/landing-fix.service';
import { CookieService } from "ngx-cookie-service";
import { NavigationEnd, Router } from "@angular/router"; 
import { BaseService } from 'src/app/shared/services/base.service';
import { PagesService } from 'src/app/pages/pages.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { Subscription } from 'rxjs';
import { SocialAuthService } from "angularx-social-login";
import { TranslateService } from '@ngx-translate/core';
import { NavbarClassService } from 'src/app/shared/services/navbarClass.service';

declare var $: any;
@Component({
  selector: 'app-topbar',
  templateUrl: './topbar-one.component.html',
  styleUrls: ['./topbar-one.component.scss']
})
export class TopbarOneComponent implements OnInit {
  storeId : any;
  currentlang : any;
  public deviceData = "device_id=1111&device_token=111&platform=web";
  @Input() storeData:any;
  @Output() valueChange = new EventEmitter();
  @Input() shoppingCartItems  :   any;
  private subscriptions: Subscription[] = [];
  public enableLogout: boolean = false;
  public multiDataOn : boolean = false;
  public offersActive: boolean = false;
  public ordersActive: boolean = false;
  public showButton: boolean = false;
  constructor(public baseService:BaseService,private fix: LandingFixService,private router: Router, private cs: CookieService, public pagesService: PagesService, public cartService: CartService, private socialAuthService: SocialAuthService, private translate: TranslateService, public navbarClassService : NavbarClassService) {   
      this.subscriptions.push(
      this.baseService.currentStoreId.subscribe((value) => {
          if(value){
              this.storeId =  value;         
          }
        })
      );
      
   }

  ngOnInit() {  
    this.showButton = true;
    if(this.router.url.includes('offers')) {
      this.offersActive = true;
    }
    if(this.router.url.includes('orders')) {
      this.ordersActive = true;
    }

    this.subscriptions.push(
      this.router.events.subscribe(event => {
        if(event instanceof NavigationEnd) {
          if(!this.router.url.includes('offers')) {
            this.offersActive = false;
          }
          if(!this.router.url.includes('orders')) {
            this.ordersActive = false;
          }
        }
      })
    );
    

    this.subscriptions.push(
      this.navbarClassService.isSearchClosed.subscribe(value => {
        if(value == true && this.router.url.includes('offers')) {
          this.offersActive = true;
        }
        if(value == true && this.router.url.includes('orders')) {
          this.ordersActive = true;
        }
      })
    );

    this.subscriptions.push(
      this.navbarClassService.toHome.subscribe(value => {
        if(value == true) {
          this.offersActive = false;
        }
      })
    );

    this.subscriptions.push(
      this.navbarClassService.searchOpen.subscribe(value => {
        if(value == true && !this.router.url.includes('home')) {
          this.offersActive = false;
          this.ordersActive = false;
        }
      })
    );

    this.subscriptions.push(
      this.navbarClassService.toOffers.subscribe(value => {
        if(value == true) {
          this.offersActive = true;
        }
      })
    );

    if (!this.cs.get('UserInfo')) {
       this.enableLogout = true;
    } else {
      this.enableLogout = false;
    }
  }

  setFlag() {
    this.ordersActive = true;
  }

  closeSearch()
  {
     $(".keyword-wrapper-web").show();
     $(".search-overlay").css("display","none");
  }
  
   // For mobile filter view
  categoriesFilter() {
    this.navbarClassService.isToOffers(true);
    this.closeSearch();
    this.router.navigate(['/offers']);
    //$('.collection-filter').css("left", "0px");
  }

  changeStore(storeLocation)
  {
    const storeLocationFull = storeLocation + '/?des=pop';
    window.location.href    = storeLocationFull;
  }

  shareUrl(){
    if(this.pagesService.userInfo || !this.baseService?.storeData?.is_referer_fn_enable){
      $('#refererAlertPopup').modal('hide');
      const showLoader = true;
      this.valueChange.emit(showLoader);
    }else{
      document.getElementById("shareLink").style.display = "none";
      $('#refererAlertPopup').modal('show');
    }
  }

  logout()
  {
    this.pagesService.userInfo = null;
    this.cartService.clearCart();
    this.showButton = false;
    this.socialAuthService.signOut().then().catch(err => { console.log(err); });
  	this.cs.delete('UserInfo', '/');
    this.cs.delete('deliveryTimeSlot', '/');
    this.cs.delete('displayDeliveryTimeSlot', '/');
    this.cs.delete('selectedAddress', '/');
    this.cs.delete('selectedAddIndex', '/');
    this.cs.delete('orderType', '/');
  	this.router.navigate(['/'+this.baseService?.storeType+'/home']);
  }

   openNav() {
      this.fix.addNavFix();
   }


   public changeLanguage(lang, storeName, direction){
    this.currentlang = lang;
    this.translate.use(lang);
    this.baseService.language = lang;
    this.baseService.lang.next(lang);
    $("body").removeClass("ltr rtl LTR RTL");
    $("html").attr("dir", direction);
    $("body").addClass(direction);
    if(this.router.url == "/")
    {
      this.baseService.storeDetails(this.deviceData);
      this.baseService.getCategories();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
   }

}