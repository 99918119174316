import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class NavbarClassService {
    constructor() {}

    public isSearchClosed: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public toHome: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public toOffers: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public searchOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);

    isSearchClosedfunction(flag) {
        this.isSearchClosed.next(flag);
    }

    isToHome(flag) {
        this.toHome.next(flag);
    }

    isToOffers(flag) {
        this.toOffers.next(flag);
    }

    isSearchOpen(flag) {
        this.searchOpen.next(flag);
    }
}