<div class="row" >
  <div class="col-lg-3">
    <div class="header-contact">
          <div class="brand-logo layout2-logo headerlogo">
              <a [routerLink]="'/'" *ngIf="storeData && storeData.logo"><img src="{{storeData.logo}}" class="img-fluid" alt="store-logo" class="store-logo-image"></a>
          </div>
    </div>
  </div>
  <div class="col-lg-9 set-align-center">
    <div class="full-width-top-section">
      <app-web-search *ngIf="baseService.isDesktopDevice && baseService?.screenOptions?.web_search?.display" ></app-web-search>
      <div class="text-right" *ngIf="!baseService?.catalogMode">
        <ul class="header-dropdown">
          <li [ngClass]="{'offers-active' : offersActive == true}" class="mobile-wishlist" *ngIf="baseService.isMobile && (baseService?.storeData?.bottom_navigation == '1' || !router.url.includes('restaurants'))">
            <a (click)="categoriesFilter()"><i class="fa fa-percent" aria-hidden="true"></i>{{ 'home' | translate }}</a>
            <span class="menu_name">{{'offers' | translate}}</span>
          </li>
          <li [ngClass]="{'orders-active' : ordersActive == true}" class="onhover-dropdownn mobile-account" *ngIf="baseService.isMobile && (baseService?.storeData?.bottom_navigation == '1' || !router.url.includes('restaurants'))">
            <a (click)="setFlag()" (click)="closeSearch()" [routerLink]="'/'+baseService?.storeType+'/orders'" data-lng="en">
            <i class="ti-settings" *ngIf=baseService.isMobile></i> 
            <!-- {{ 'my-account' | translate }} -->
          </a>
          <span class="menu_name">{{'my-orders' | translate}}</span>
          </li>
          <!-- <li class="order-online-list" *ngIf="baseService.isDesktopDevice">
            <a class="order-online-link" [ngStyle]="{'color': baseService?.storeData?.web_homepage_headings?.order_online?.color}" [routerLink]="'/restaurants/search/all'">{{baseService?.storeData?.web_homepage_headings?.order_online?.title || baseService?.pagesData?.homepage?.header_order_now}}</a>
          </li>   -->
          <!--  <li *ngFor="let store of baseService?.storeData?.languages;" (click)="changeLanguage(store?.language, store?.name, store?.direction)">
                {{store?.name}}
            </li> -->
          <li class="onhover-dropdown mobile-setting" *ngIf="baseService.isDesktopDevice && (storeData?.languages?.length > 1)">
            <div class="select-lang">{{ currentlang ? currentlang : 'En'}}</div>
            <div class="language_setting">
              <!-- <h6>language</h6> -->
              <ul class="onhover-show-div">
                <li *ngFor="let store of baseService?.storeData?.languages;">
                  <a (click)="changeLanguage(store?.language, store?.name, store?.direction)">
                  {{store?.name}}</a>
                </li>
               <!--  <li><a (click)="changeLanguage('en', 'English', 'LTR')">English</a> </li>
                <li><a (click)="changeLanguage('ar', 'Arabic', 'RTL')">Arabic</a> </li> 
               <li><a [routerLink]="" (click)="changeLanguage('en', 'English')">English</a> </li>
                <li><a [routerLink]="" (click)="changeLanguage('ma', 'Malysia')">Malysia</a> </li>     
                <li><a [routerLink]="" (click)="changeLanguage('fr', 'French')">French</a> </li>
                <li><a [routerLink]="" (click)="changeLanguage('hi', 'Hindi')">Hindi</a> </li>
                <li><a [routerLink]="" (click)="changeLanguage('ban', 'Bangla')">Bangla</a> </li>
                <li><a [routerLink]="" (click)="changeLanguage('spa', 'Spanish')">Spanish</a> </li>
                <li><a [routerLink]="" (click)="changeLanguage('ger', 'German')">German</a> </li>
                <li><a [routerLink]="" (click)="changeLanguage('rus', 'Russian')">Russian</a> </li>
                <li><a [routerLink]="" (click)="changeLanguage('indo', 'Indonesian Basha')">Indonesian Basha</a> </li> -->
              </ul>
            </div>
          </li>
          <li class="onhover-dropdown mobile-account after-login" *ngIf="pagesService?.userInfo && baseService.isDesktopDevice">
            <a (click)="closeSearch()">
             <!-- <img src="assets/images/user.png" alt=""> -->
             <i *ngIf="baseService?.storeData?.show_signup_button_as == '1'" class="fa fa-user-circle-o" aria-hidden="true"></i>
             <div class="my-account-button" *ngIf="baseService?.storeData?.show_signup_button_as == '2'">
               <span>My Account</span>
             </div>
            <!-- {{ 'my-account' | translate }} -->
            </a>
            <ul class="onhover-show-div">
              <li [routerLink]="'/'+baseService?.storeType+'/dashboard'">
                <a>
                    {{'my-profile' | translate}}
                </a>
              </li>
              <li [routerLink]="'/'+baseService?.storeType+'/orders'">
                <a>
                    {{'my-orders' | translate}}
                </a>
              </li>
              <li *ngIf="baseService?.storeData?.wallet_setting != 0" [routerLink]="'/'+baseService?.storeType+'/wallet'">
                <a>
                    {{'my-wallet' | translate}}
                </a>
              </li>
              <li *ngIf="baseService?.storeData?.loyality && baseService?.storeData?.loyality==1" [routerLink]="'/'+baseService?.storeType+'/loyality'">
                <a>
                    {{'loyality-points' | translate}}
                </a>
              </li>
              <li [routerLink]="" *ngIf="pagesService?.userInfo && baseService?.storeData?.is_referer_fn_enable;else showshare">
                <a (click)="shareUrl()">
                    {{'refer-earn' | translate}}
                </a>
              </li>
              <ng-template #showshare>
                <li [routerLink]="" >
                <a (click)="shareUrl()">
                    {{'share' | translate}}
                </a>
              </li>
              </ng-template>
              <li (click)="logout()">
                <a>
                  {{'logout' | translate}}
                </a>
              </li>
            </ul>
          </li>
          <li [ngClass]="baseService?.storeData?.show_signup_button_as == '1' ? 'login-icon' : ''" class="mobile-account"  *ngIf="!pagesService?.userInfo && baseService.isDesktopDevice">
            <div *ngIf="baseService?.storeData?.show_signup_button_as == '1'; else showButton">
            <a (click)="closeSearch()" [routerLink]="'/'+baseService?.storeType+'/login'">
              <!-- <img src="assets/images/user.png" alt=""> -->
              <i class="fa fa-user-circle-o" aria-hidden="true"></i>
              <!-- {{baseService?.pagesData?.homepage?.header_login_text}} -->
            </a>
          </div>
          <ng-template #showButton>
            <div class="log_button" *ngIf="!pagesService?.userInfo && baseService.isDesktopDevice">
              <a (click)="closeSearch()" [routerLink]="'/'+baseService?.storeType+'/login'">
                <button type="button" class="btn">Login/SignUp</button>
              </a>
            </div>
          </ng-template>
          </li>
          <li class="onhover-div mobile-cart" *ngIf="baseService.isDesktopDevice">
            <div> 
            <a [routerLink]="'/'+baseService?.storeType+'/cart'">
              <!-- <img src="assets/images/icon/cart.png" class="img-fluid" alt="cart"> -->
              <i class="ti-shopping-cart"></i>
            </a> 
            <span [routerLink]="'/'+baseService?.storeType+'/cart'" *ngIf="shoppingCartItems" [class]="shoppingCartItems ? 'home-items' : ''" class="cart_value">{{shoppingCartItems}} </span>
            </div>
          </li>
        </ul>
      </div>
    </div>  
  </div>  
</div>