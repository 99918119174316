import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  public heading: BehaviorSubject<string> = new BehaviorSubject('');
  public subHeading: BehaviorSubject<string> = new BehaviorSubject('');
  public top_heading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isCartHeader: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public home_heading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor() { }

  setHeading(text, isTopHeading = false) {
    this.top_heading.next(isTopHeading);
    this.isCartHeader.next(false);
    this.home_heading.next(false);
    this.heading.next(text);
  }

  setSubHeading(text) {
    this.subHeading.next(text);
  }

  setCartHeader() {
    // this.subHeading.next(text);
    this.heading.next('Summary');
    this.top_heading.next(false);
    this.home_heading.next(false);
    this.isCartHeader.next(true);
  }

  setHomeHeader() {
    // this.subHeading.next(text);
    this.heading.next('Summary');
    this.top_heading.next(true);
    this.isCartHeader.next(false);
    this.home_heading.next(true);
  }
}
