<nav id="main-nav">
  <!-- <div class="toggle-nav" (click)="toggleNav()">
    <i class="fa fa-bars sidebar-bar"></i>
  </div> -->
  <!-- Sample menu definition -->
  <!-- <ul id="main-menu" class="sm pixelstrap sm-horizontal" [class.m-toggle]="toggleNavBar"> -->
    <ul id="main-menu" class="sm pixelstrap" [class.m-toggle]="toggleNavBar">  
    <li *ngIf="baseService?.isMobile">
      <div class="mobile-back text-right" (click)="toggleNav()">
        {{'back' | translate}}<i class="fa fa-angle-right pl-2" aria-hidden="true"></i>
      </div>
    </li>
    <li *ngFor="let menuItem of menuItems" [routerLinkActive]="menuItem.type === 'sub' ? '':'active'" [routerLinkActiveOptions]="{ exact: true }">
       <!-- Link -->
       <a [routerLink]="!menuItem.type ? null : [menuItem.path]" *ngIf="menuItem.type === 'link'">
          {{menuItem.title | translate}}
       </a>
    </li>
         
    <!-- 1st Level Menu -->

    <!-- <li [ngClass]="{'active': selectedItem == item}">
        <a [routerLink]="'/'+baseService?.storeType+'/home'" (click)="showActiveClass($event)">
           {{'Home' | translate}}
        </a>
    </li>
    <li>
        <a [routerLink]="'/'+baseService?.storeType+'/offers'">
           {{'Latest Offers' | translate}}
        </a>
    </li>
    <li>
      <a [routerLink]="'/'+baseService?.storeType+'/about-us'">
         {{'About' | translate}}
      </a>
  </li>
   <li>
      <a [routerLink]="'/'+baseService?.storeType+'/partners'">
         {{'Partners' | translate}}
      </a>
  </li>
  <li>
      <a [routerLink]="'/'+baseService?.storeType+'/contact'">
         {{'Contact' | translate}}
      </a>
  </li> -->

  <!-- <li class="mega" id="hover-cls">
    <a href="javascript:void(0)">
       {{'Categories' | translate}}
    </a>
    <ul class="mega-menu full-mega-menu" id="mega-menu">
        <ng-container>
        <li>
          <div class="container">
            <div class="row">
              <div class="col mega-box" *ngFor="let category of baseService?.categories; let i = index">
                <div class="link-section" *ngIf="i % 2 == 0">
                  <div class="menu-content">
                    <ul>
                      <li>
                        <a href="javascript:void(0)" (click)="getSubCatProducts(category)">
                           {{category.title.toUpperCase() | translate}}
                        </a>
                      </li>
                      <li *ngIf="i + 1 < baseService.categories.length">
                        <a href="javascript:void(0)" (click)="getSubCatProducts(baseService?.categories[i + 1])">
                           {{baseService?.categories[i + 1].title.toUpperCase() | translate}}
                        </a>
                      </li>
                       <li *ngIf="i + 2 < categories.length">
                        <a routerLink='/home/left-sidebar/collection/{{categories[i + 2].id}}'>
                           {{categories[i + 2].title | translate}}
                        </a>
                      </li> 
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        </ng-container>
    </ul>
</li> -->

  </ul>
</nav>
