<div class="profile-container">
  <div class="profile-image-container">
      <img src="assets/images/profileimg.png" alt="profile-image" />
      <h3 class="profile-name">
        <span *ngIf="pagesService?.userInfo">{{'welcome' | translate}} <b>{{pagesService?.userInfo?.full_name}}</b></span>
        <a (click)="toggleMenu()" *ngIf="!pagesService?.userInfo" [routerLink]="'/'+baseService?.storeType+'/login'">{{baseService?.pagesData?.homepage?.header_login_text}}</a>
      </h3>
  </div>
</div>
<div class="wallet_balance" *ngIf="pagesService?.userInfo">
  <ul class="balance">
    <span class="refund_check"><i class="ti-wallet" aria-hidden="true"></i></span>
    <li>
       <a (click)="toggleMenu()" [routerLink]="['/wallet']">{{'wallet-balance' | translate}}<span [innerHTML]="baseService?.currency + walletAmount"></span></a>
    </li>
  </ul>
</div>

<!-- 1st Level Menu -->
<!-- Sample menu definition -->
<ul id="sub-menu" class="sm pixelstrap sm-vertical custom_menu">

  <!-- 1st Level Menu -->
  <li *ngFor="let menuItem of menuItems" [routerLinkActive]="menuItem.type === 'sub' ? '':'active'" [routerLinkActiveOptions]="{ exact: true }">
    <!-- Click -->
    <a href="javascript:void(0)" (click)="menuClickEvent(menuItem.clickEvent)" *ngIf="menuItem.type === 'click'">
      <img [src]="menuItem.icon">
     <span> {{menuItem.title | translate}}</span>
 
   </a>
    <!-- Sub -->
    <a href="javascript:void(0)" *ngIf="menuItem.type === 'sub'" class="select-language-dropdown">
      <div class="left-language">
        <img [src]="menuItem.icon">
        <span>{{menuItem.title | translate}}</span>
     
      </div>  
      <div class="right-language-arrow">
        <i class="fa fa-angle-down" aria-hidden="true"></i>
      </div>  
   </a>
   <!-- Link -->
   <a (click)="toggleMenu()" [routerLink]="!menuItem.type ? null : [menuItem.path]" *ngIf="menuItem.type === 'link'"> <img [src]="menuItem.icon">
    <span> {{menuItem.title | translate}}</span>
   </a>
   <!-- External Link -->
   <a href="{{ !menuItem.type ? null : menuItem.path }}" *ngIf="menuItem.type === 'extLink'">
    <img [src]="menuItem.icon">
    <span>{{menuItem.title | translate}}</span>
   </a>
   <!-- External Tab Link -->
   <a href="{{ !menuItem.type ? null : menuItem.path }}" *ngIf="menuItem.type === 'extTabLink'">
    <img [src]="menuItem.icon">
    <span> {{menuItem.title | translate}}</span>
   </a>

   <div *ngIf="menuItem.type==='separator'" class="seps"></div>

   <!-- 2nd Level Menu -->
   <ul *ngIf="menuItem.children" class="mega-menu clothing-menu" [id]="'mega-menu'">
    <!-- Small And Medium Mega Menu Start-->
    <ng-container >
      <li>
        <div class="row m-0">
          <div class="col-xl-4">
            <div class="link-section">
              <ul>
                <li *ngFor="let childrenItem of menuItem.children" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                  <a (click)="toggleMenu()" [routerLink]="!childrenItem.type ? null : [childrenItem.path]" *ngIf="childrenItem.type === 'link'"><i [class]="childrenItem.icon"></i>
                    {{childrenItem.title | translate}}
                 </a>
                 <a (click)="menuClickEvent(childrenItem.clickEvent, childrenItem.class, childrenItem.title, childrenItem.direction)" *ngIf="childrenItem.type === 'click'"><i [class]="childrenItem.icon"></i>
                    {{childrenItem.title | translate}}
                 </a>
                  <!-- <a href="javascript:void(0)" (click)="getSubCatProducts(childrenItem)">
                    {{childrenItem.title | translate}}
                 </a> -->
                </li>
              </ul>    
            </div>
          </div>
        </div>
      </li>
    </ng-container>

      <!-- Small And Medium Mega Menu End-->
   </ul>
   
  </li>

<li *ngIf="pagesService?.userInfo">
  <a (click)="logout()">
    <img src="./assets/images/left-menu/logout.png" alt="">
    <span> {{'Logout' | translate}} </span>
  </a>
</li>
<!-- </div> -->
</ul>
<div class="social-links" *ngIf="socialExists">
  <div class="follow"><h3>{{'follow-us-on' | translate}}</h3></div>
  <ul class="social-media">
    <li *ngIf="socialValues?.facebook"><a target="_blank" href="{{socialValues?.facebook}}"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
     <li *ngIf="socialValues?.twitter"><a target="_blank" href="{{socialValues?.twitter}}"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
     <li *ngIf="socialValues?.linkedin"><a target="_blank" href="{{socialValues?.linkedin}}"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
     <li *ngIf="socialValues?.youtube"><a target="_blank" href="{{socialValues?.youtube}}"><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li>
     <li *ngIf="socialValues?.instagram" ><a target="_blank" href="{{socialValues?.instagram}}"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
  </ul>
</div>
