import './polyfills';
import './vendor/pace/pace.min.js';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
//import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Sentry.init({
//   dsn: "https://0fb827c287924d1eaf18ed4285d68428@o468789.ingest.sentry.io/5497197",
//   integrations: [
//     new Integrations.BrowserTracing({
//       tracingOrigins: ['localhost', 'http://devmarketplace.restroapp.com'],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
