import { Component, OnInit, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MouseEvent } from "@agm/core";
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { PagesService } from "../../../pages/pages.service";
import { ToastrService } from "ngx-toastr";
import { BaseService } from "../../services/base.service";
import { CookieService } from "ngx-cookie-service";
import { Subscription } from "rxjs";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";
declare var $: any;
@Component({
  selector: "app-footer-one",
  templateUrl: "./footer-one.component.html",
  styleUrls: ["./footer-one.component.scss"],
})
export class FooterOneComponent implements OnInit {
  @Input() storeData: any;
  zoom: number = 8;
  lat: number = 51.673858;
  lng: number = 7.815982;
  infocontent: any;
  contactUsFrmBtm: FormGroup;
  storeAddress = null;
  showDraggableMap = false;
  currentstoredata: any;
  storeId: any;
  aboutData: any;
  domain: any;
  webdomain: any;
  showthird: boolean = false;
  private subscriptions: Subscription[] = [];
  apiKey = "AIzaSyC3iMMwUmkM3A46umRjArjJH6DGF20NVZs";
  isMultipleStore: boolean;
  socialExists: boolean = false;
  socialValues: any;
  brandID: any;
  appofferCookie: string;
  deviceType: any;
  currentstoredataApp: any;
  currentStoreBoolean: boolean = false;
  constructor(
    public baseService: BaseService,
    public toastrService: ToastrService,
    public sanitizer: DomSanitizer,
    public pagesService: PagesService,
    public _cs: CookieService,
    public router: Router,
    private route: ActivatedRoute,
    private deviceService: DeviceDetectorService
  ) {
    this.deviceType = this.deviceService.getDeviceInfo().device;
    this.subscriptions.push(
      this.baseService.currentStoreId.subscribe((value) => {
        if (value) {
          this.storeId = value;
        }
      })
    );
    this.subscriptions.push(
      this.baseService.currentStoreData.subscribe((curVal) => {
        this.currentStoreBoolean = true;
        if (Object.keys(curVal).length > 0) {
          if(curVal['show_take_to_app_popup'] === '1') {
            this.appofferCookie = this._cs.get("getAppPopupModal");
            this.currentstoredataApp = curVal;
            if (this.appofferCookie === "" || !this.appofferCookie) {
              setTimeout(() => {
                $("#getAppmodal").modal("show");
              }, 500);
            } else {
              $("#getAppmodal").modal("hide");
            }
          }
          this.currentstoredata = curVal;
          this.lat = parseFloat(this.currentstoredata?.lat);
          this.lng = parseFloat(this.currentstoredata?.lng);
          this.infocontent = this.currentstoredata?.location;
          this.storeAddress = this.sanitizer.bypassSecurityTrustResourceUrl(
            "https://maps.googleapis.com/maps/api/staticmap?center=" +
              this.lat +
              "," +
              this.lng +
              "&zoom=" +
              this.zoom +
              "&size=538x200&maptype=roadmap&markers=color:red%7C" +
              this.lat +
              "," +
              this.lng +
              "&key=" +
              this.apiKey
          );
          let stripedHtml = $("<div>")
            .html(this.currentstoredata["about_us"])
            .text()
            .substring(0, 200);
          this.aboutData = stripedHtml;
          this.domain = this.currentstoredata["domain"];
          this.webdomain = this.domain
            ? this.domain.replace(/(^\w+:|^)\/\//, "")
            : "";
          if (
            this.currentstoredata["android_share_link"] ||
            this.currentstoredata["iphone_share_link"]
          ) {
            this.showthird = true;
          }
        }
      })
    );
  }

  ngOnInit() {
    if (this.currentstoredata !== 'undefined' && this.currentstoredata?.show_take_to_app_popup === "1") {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          $("#getAppmodal").modal("hide");
        }
      });
    }

    this.subscriptions.push(
      this.baseService.isMultipleStore().subscribe((val) => {
        this.isMultipleStore = val;
        if (val === true) {
          this.route.queryParams.subscribe((params) => {
            //console.log(params['des']);
            if (params["des"] && params["des"] == "pop") {
              $("#multipleStoreData").modal("hide");
              this._cs.set("multiStoreModalShown", "false");
            } else if (this._cs.get("multiStoreModalShown") == "") {
              $("#multipleStoreData").modal("show");
              this._cs.set("multiStoreModalShown", "true");
            }
          });
        }
      })
    );

    this.contactUsFrmBtm = new FormGroup({
      name: new FormControl("", Validators.required),
      mobile: new FormControl("", [
        Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
      ]),
      city: new FormControl("", Validators.required),
      email: new FormControl("", [Validators.required, Validators.email]),
      message: new FormControl("", Validators.required),
    });

    this.baseService.brandID.subscribe((value) => {
      if (value) {
        this.brandID = value;
        this.getSocialLinks();
      }
    });
  }

  clean(obj) {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ""
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  hideAppModal() {
    $("#getAppmodal").modal("hide");
    const dateNow = new Date();
    dateNow.setHours(dateNow.getHours() + 2);
    this._cs.set("getAppPopupModal", "false", dateNow);
  }

  externalForm(link) {
    window.location.href = link;
  }

  getSocialLinks() {
    const socialData = "device_id=1111&device_token=111&platform=web";
    this.subscriptions.push(
      this.pagesService.getAllSocials(socialData).subscribe((res) => {
        if (res["success"]) {
          const data = res["data"];
          delete data.id; //unsetting id
          const socialValues = this.clean(data);
          if (Object.keys(socialValues).length === 0) {
            this.socialExists = false;
          } else {
            this.socialValues = socialValues;
            this.socialExists = true;
          }
        } else {
          this.toastrService.error(res["message"], "Error!");
        }
      })
    );
  }

  changeStore(storeLocation) {
    $("#multipleStoreData").modal("hide");
    this._cs.set("multiStoreModalShown", "false");
    const storeLocationFull = storeLocation + "/?des=pop";
    window.location.href = storeLocationFull;
  }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`);
  }

  markerDragEnd(m: marker, $event: MouseEvent) {
    console.log("dragEnd", m, $event);
  }

  enableDraggableMap() {
    this.showDraggableMap = true;
  }

  sendEmail() {
    const queryArr: any = {};
    queryArr.name = this.contactUsFrmBtm.value.name;
    queryArr.email = this.contactUsFrmBtm.value.email;
    queryArr.mobile = this.contactUsFrmBtm.value.mobile;
    queryArr.city = this.contactUsFrmBtm.value.city;
    const today = new Date();
    const date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();
    const time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date + " " + time;
    queryArr.datetime = dateTime;
    queryArr.message = this.contactUsFrmBtm.value.message;
    const myJSON = JSON.stringify(queryArr);
    const enquiryData =
      "device_id=1111&device_token=111&platform=web&query=" + myJSON;
    this.subscriptions.push(
      this.pagesService.sendEnquiry(enquiryData).subscribe((res) => {
        if (res["success"]) {
          this.toastrService.success(
            "Your Enquiry has been sent. We will contact you shortly.",
            "Success!"
          );
          this.contactUsFrmBtm.reset();
        } else {
          this.toastrService.error(res["message"], "Error!");
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
