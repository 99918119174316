import { Component, OnInit } from '@angular/core';
import { Menu } from './navbar-items';
import { Router } from '@angular/router';
import { BaseService } from 'src/app/shared/services/base.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public categories = [];
  public toggleNavBar : boolean = false;
  public href: string = "";
  public menuItems: Menu[];
  private subscriptions: Subscription[] = [];

  constructor( public baseService: BaseService, public translate: TranslateService, public toastrService: ToastrService,private router: Router) {   }

  ngOnInit() {
    this.subscriptions.push(
    this.baseService.menuDataAccessed.subscribe((value) => {
      if(value)
      {
        this.menuItems = [
        (value['Home'] == '1' ? { path: '/' + this.baseService?.storeType + '/', title: 'home', type: 'link' } : {}) ,
        (value['Latest_Offers'] == '1' ? { path: '/' + this.baseService?.storeType + '/offers', title: 'latest-offers', type: 'link'} : {}),
        (value['Runners'] == '1' ? { path: '/' + this.baseService?.storeType + '/runners', title: 'runner-registration', type: 'link'} : {}),  
        (value['Partners'] == '1' ? { path: '/' + this.baseService?.storeType + '/partners', title: 'merchant-registration', type: 'link'} : {}),
        (value['About'] == '1' ? { path: '/' + this.baseService?.storeType + '/about-us', title: 'about-us', type: 'link'} : {}),
        (value['Contact'] == '1' ? { path: '/' + this.baseService?.storeType + '/contact', title: 'contact-us', type: 'link'} : {}),   
         
        ];    
      }
    })
    );

    
  }

  showActiveClass(event)
  {
    console.log("event", event.target.value);
  }

  externalForm(link) {
    window.location.href = link;
  }

  toggleNav() {
    this.toggleNavBar = !this.toggleNavBar 
  }

   // Get sub-cat products
   getSubCatProducts(category){
    this.router.navigate(['/'+this.baseService?.storeType+'/collection/'+(((category.title.replace(/[^&a-zA-Z ]/g,'-')).replace(/ /g,"-")).toLowerCase())+'/'+category.sub_category[0].id]);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
   }
}
