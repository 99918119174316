import { Component, Renderer2, ViewChild, OnInit , ElementRef} from '@angular/core';
import { trigger, transition, style, animate } from "@angular/animations";
import { BaseService } from '../../shared/services/base.service';
import { PagesService } from 'src/app/pages/pages.service';
import { ToastrService } from 'ngx-toastr';
import { distinctUntilChanged, debounceTime, filter } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NavbarClassService } from '../services/navbarClass.service';

declare var $: any;
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [  // angular animation
    trigger('Animation', [
      transition('* => fadeOut', [
        style({ opacity: 0.1 }),
        animate(1000, style({ opacity: 0.1 }))
      ]),
      transition('* => fadeIn', [
        style({ opacity: 0.1 }),
        animate(1000, style({ opacity: 0.1 }))
      ])
    ])
  ]
})

export class SearchComponent implements OnInit {
  @ViewChild('searchText') searchTextRef;
  @ViewChild('searchText2') searchTextRef2;
  public products: any = [];
  public searchProducts: any = [];
  public animation: any;
  public searchTerms: any = '';
  public show: boolean = false;
  apiHit = false;
  checked = false;
  searchTags: any = [];
  search: any;
  restaurants = [];
  dishesList =  [];
  isMenuOpen : boolean = false;
  timeout: any = null;
  lat : number;
  lng : number;
  zoom: number;
  searchKeyword : any;
  language : any = "eng";
  defaultImage = "./assets/images/home-banner/defalut-image.png";
  @ViewChild('keywordsearch')
  public keywordElementRef: ElementRef;
  searchInput1: FormControl = new FormControl();
  private subscriptions: Subscription[] = [];
  closeFlag : boolean = false;
  constructor(public baseService: BaseService,public translate: TranslateService, private toastrService: ToastrService, private router: Router, private renderer: Renderer2, public pagesService:PagesService, public navbarClassService: NavbarClassService) {
     this.subscriptions.push(
      this.baseService.userCoords.subscribe((value) => {

        if(value)
        {        
          this.lat = value[0];
          this.lng = value[1];
        }
      })
    );

  }


  ngOnInit() {
    //this.keywordElementRef.nativeElement.value = '';
    this.searchInput1.valueChanges
      .pipe(
        debounceTime(400),
        filter(t => t && t.length > 0),
        distinctUntilChanged()
      ).subscribe(
        text => {
          this.searchItembyTerm(text);
        });

          this.baseService.lang.subscribe((value) => {
            if(value)
            {
              this.language = value;
            }
          });
  }
  

  // ngAfterViewInit() {
  //     this.renderer.setProperty(this.keywordElementRef.nativeElement, 'value', '');
  // }

  ngOnDestroy() {
    this.checked = false;
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    //this.mainService.unregister(this.searchTextRef.nativeElement);
  }

  onKeySearch(event: any) {
    this.apiHit = true;
    this.searchKeyword = event.target.value;
      if(this.timeout){
        clearTimeout(this.timeout);
      }
      var $this = this;
      this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.getSearchRestaurantsList(event.target.value);
      }
    }, 700);
  }

  getSearchRestaurantsList(keyword)
  {
    // const key = keyword ? keyword : "all";
    if(keyword) {
      const restodata = "lat="+this.lat+"&lng="+this.lng+'&search_by=Keyword&keyword='+keyword;
      this.subscriptions.push(
        this.pagesService.getRestaurantsList(restodata).subscribe((res) => {
          if (res) {
            this.apiHit = false;
            this.isMenuOpen  = true;
            
            this.restaurants = res['data'] && res['data'].length > 0 ? res['data'] : [];
            if(this.restaurants.length) {
              this.calculateStoreTimmings(this.restaurants);
            }
            this.dishesList  = res['dishes'] && res['dishes'].length > 0 ? res['dishes'] : [];
          }
        })
      );    
    } else {
      this.isMenuOpen = false;
      this.apiHit = false;
      this.closeFlag = false;
      this.restaurants = [];
      this.dishesList = [];
    }
  }

  calculateStoreTimmings(allRestaurants)
  {
    allRestaurants.filter((singleResto) => {
      if(singleResto.store_status == '1') {
        if(singleResto.timimg?.is24x7_open == '1') {
          singleResto.storeOpen = true;
        } else {
          var a = new Date();
          var weekdays = new Array(7);
          weekdays[0] = "sun";
          weekdays[1] = "mon";
          weekdays[2] = "tue";
          weekdays[3] = "wed";
          weekdays[4] = "thu";
          weekdays[5] = "fri";
          weekdays[6] = "sat";
          var r = weekdays[a.getDay()];
          if(singleResto?.timimg?.store_open_days && ((singleResto?.timimg?.store_open_days).split(',')).indexOf(r) > -1) {
            const openHoursFrom = this.formatTime(singleResto?.timimg.openhours_from);
            const openHoursTo   = this.formatTime(singleResto?.timimg.openhours_to);
            if(Date.parse('01/01/2011 '+(new Date().getHours()+':'+new Date().getMinutes())) >= Date.parse('01/01/2011 '+ openHoursFrom) && Date.parse('01/01/2011 '+(new Date().getHours()+':'+new Date().getMinutes())) <= Date.parse('01/01/2011 '+ openHoursTo)){
                singleResto.storeOpen = true;
              } else
              {
                singleResto.storeOpen = false;
              }
            } else
            {
              singleResto.storeOpen = false;
            }
          }   
      } else {
        singleResto.storeOpen = false;
      }                
      });
      this.restaurants = allRestaurants;   
  }

  formatTime(time) {
    const split = time.split(':');
    const min = split[1].split('')[0] + split[1].split('')[1];
    const am_pm = split[1].split('')[2] + split[1].split('')[3];
    let hours = split[0];
    if (hours == '12') {
      hours = '00';
    }
    if (am_pm == 'pm') {
      hours = parseInt(hours, 10) + 12;
    }
    return hours + ':' + min;
  }

   getResults()
  {
      const key = this.keywordElementRef.nativeElement.value ? this.keywordElementRef.nativeElement.value : "all";
      this.getSearchRestaurantsList(key);
  }

  openSearch() {
    let tsnString = (document.getElementById("exampleInputPassword1") as HTMLTextAreaElement).value;
    tsnString = '';
    $('.search-overlay').show(function () {
      $(".keyword-wrapper-web").css("display", "none");
      document.getElementById("exampleInputPassword1").focus();
    });
  }

  closeSearch() {
    this.search = " ";
    this.searchProducts = [];
    this.keywordElementRef.nativeElement.value = null;
    this.restaurants = [];
    this.dishesList = [];
    this.apiHit = false;
    this.checked = false;
    this.closeFlag = true;
    this.navbarClassService.isSearchClosedfunction(true);
    $(".keyword-wrapper-web").show();
    $(".search-overlay").css("display", "none");
  }

  setTerm(term) {
    if (term) {
      this.search = term;
    }
  }

  searchItembyTerm(term) {
    if (term) {
      this.apiHit = true;
      const serchData = 'device_id=1234&platform=123&keyword=' + term + '&lang='+this.language;
      this.baseService.getSearchByKeyword(serchData, 2).subscribe((res) => {
        this.checked = true;
        this.apiHit = false;
        if (res['success']) {
          let dataArray = res['data'];
          let specialProducts = [];
          dataArray.forEach(function (element) {
            element.products.forEach(function (product) {
              specialProducts.push(product)
            });
          });
          this.searchProducts = specialProducts;
        }
        else {
          this.searchProducts = [];
        }
      });
    }
  }


  onBlur() {
    const key = (this.searchTextRef.nativeElement.value) ? (this.searchTextRef.nativeElement.value) : "all";
    this.router.navigate(['/stores/search/'+key]);
    this.closeSearch();
    return false;
  }

  onFocus() {
    // if (!this.searchTags.length) {
    //   const verifyData = '';
    //   this.baseService.getSearchTags(verifyData).subscribe((res) => {
    //     if (res['success']) {
    //       this.searchTags = res['data'];
    //     }
    //     else {
    //       //this.toastrService.error(res['message']);
    //     }
    //   });
    // }
  }

}
