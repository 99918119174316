<!-- header start -->
<header class="sticky header-2  header-type inner-header" id="sticky" [ngClass]="{'fixed-top': !(url.indexOf('restaurants') > -1)}">
  <div class="mobile-fix-option" *ngIf="!router.url.includes('cart') && !router.url.includes('checkout') && !router.url.includes('orders?currentOrderDetail') && (baseService?.storeData?.bottom_navigation == '1' || !router.url.includes('restaurants'))"></div>
  <div class="top-header">
     <div class="container" *ngIf="!router.url.includes('cart') && !router.url.includes('checkout') &&  !router.url.includes('orders?currentOrderDetail') && baseService.isMobile">
        <app-topbar [shoppingCartItems]="shoppingCartItems.length" [storeData]="storeData" (valueChange)="onchange($event)"></app-topbar>
     </div>
     <div class="container" *ngIf="baseService.isDesktopDevice">
        <app-topbar [shoppingCartItems]="shoppingCartItems.length" [storeData]="storeData" (valueChange)="onchange($event)"></app-topbar>
     </div>
  </div>
  <div class="head_middle">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 top-space">
                    <div class="main-menu border-section border-top-0" *ngIf="baseService.isMobile">
                        <div class="menu-left">
                            <div class="navbar">
                                <a (click)="openNav()" *ngIf="!(url.indexOf('cart') > -1) && !(url.indexOf('orders?currentOrderDetail') > -1) &&!(url.indexOf('checkout') > -1) && !(url.indexOf('/product/') > -1) && !(url.indexOf('/merchant/') > -1) && !(url.indexOf('/stores/') > -1); else showBackButton;">
                                    <!-- <i class="fa fa-bars sidebar-bar" aria-hidden="true"></i> -->
                                    <img src="assets\images\menu.png" alt="">
                                </a>
                                <ng-template #showBackButton>
                                   <a class="back-btn" (click)="goBack()">
                                    <i class="fa fa-angle-left"></i>
                                    </a>
                                </ng-template>
                                <!-- <a class="shinki1" [routerLink]="'/'+baseService?.storeType+'/home'" *ngIf="url && (url.indexOf('cart') > -1 || url.indexOf('/collection/') > -1 || url.indexOf('/restaurants/') > -1)">
                                    <i class="fa fa-angle-left"></i>
                                </a> -->
                                <!-- <a class="shinki2" [routerLink]="'/'+baseService?.storeType+'/cart'" *ngIf="url && url.indexOf('checkout') > -1">
                                    <i class="fa fa-angle-left"></i>
                                </a> -->
                                <div id="mySidenav" class="sidenav">
                                  <a [routerLink]="" class="sidebar-overlay" (click)="closeNav()"></a>
                                  <nav>
                                    <div (click)="closeNav()">
                                      <div class="sidebar-back text-left">
                                        <i class="fa fa-angle-left pr-2" aria-hidden="true"></i> {{'back' | translate}}
                                      </div>
                                    </div>
                                    <app-left-menu [shoppingCartItems]="shoppingCartItems" (valueChange)="onchange($event)"></app-left-menu>
                                  </nav>
                               </div>
                            </div>
                        </div>
                        <div class="header_heading text-center">
                          <!-- <h5 *ngIf="url.indexOf('/'+baseService?.storeType+'/home') > -1 && baseService?.storeData">{{baseService?.storeData?.city}}</h5> -->
                          <!-- Product Page -->
                          <h3 *ngIf="!(headerService.top_heading | async) && !(headerService?.isCartHeader | async) && !(headerService.home_heading | async)">{{headerService.heading | async}}</h3>
                          <div class="title_headings">
                             <!-- Home Page -->   
                             <!-- <div class="location-search-block"> -->
                              <!-- <input type="text" placeholder="" value="" class=""> -->                
                              <!-- <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder ="{{formattedAddress}}" autocorrect="off" id="search" autocapitalize="off" spellcheck="off" type="text" #search>
                              <i class="fa fa-map-marker" aria-hidden="true"></i>
                              <i class="fa fa-caret-down" aria-hidden="true"></i>
                          </div> -->
                          <!-- <div class="location-search-block-mobile-outer">
                            <app-web-search></app-web-search>
                          </div> -->
                              <div class="top_heding" *ngIf="(headerService.home_heading | async)">
                                  <a *ngIf="multiDataOn; else showtitle" (click)="openMultiPopUp()" class="multidd">
                                        <h3 *ngIf="baseService?.storeData?.name">{{baseService?.storeData?.name}}</h3>         
                                        <h5 *ngIf="baseService?.storeData?.home_page_subtitle_status && baseService?.storeData?.home_page_subtitle">{{baseService?.storeData?.home_page_subtitle}}</h5>
                                     </a>
                                  <ng-template #showtitle>
                                    <h3 *ngIf="baseService?.storeData?.home_page_title_status && baseService?.storeData?.home_page_title">{{baseService?.storeData?.home_page_title}}</h3>
                                    <h5 *ngIf="baseService?.storeData?.home_page_subtitle_status && baseService?.storeData?.home_page_subtitle">{{baseService?.storeData?.home_page_subtitle}}</h5>
                                  </ng-template>      
                              </div>
                              <!-- <p><span class="input" role="textbox" contenteditable>9</span> hugs</p> -->
                              <div class="mobile_location">
                                <div *ngIf="nothome == false && baseService.isMobile && !router.url.includes('partners') && baseService?.storeData?.is_loc_popup_enabled !== '1'"  (click)="openLocation()" class="search-location-pwa">
                                  <span [innerHtml]="formattedAddress"></span>
                                  <i aria-hidden="true" class="fa fa-chevron-down"></i>
                                </div>
                                <div *ngIf="nothome == false && baseService.isMobile && !router.url.includes('partners') && baseService?.storeData?.is_loc_popup_enabled === '1'"  (click)="openLocationPopup()" class="search-location-pwa">
                                 <span [innerHtml]="locationName"></span>
                                 <i aria-hidden="true" class="fa fa-chevron-down"></i>
                               </div>
                               </div>
                              <!-- Cart Page -->
                              <div *ngIf="(headerService?.isCartHeader | async) && !(headerService.home_heading | async)" class="top_heding">
                                  <!-- <h3>Summary</h3> -->
                                  <h3>{{headerService.heading | async}}</h3>
                                  <h5>you have {{shoppingCartItems?.length}} {{shoppingCartItems?.length==1 ? ('item' | translate): ('items' | translate)}} {{'in-your-cart' | translate}}</h5>
                                  <!-- <h5>ASYNC SUBHEADER = {{headerService.subHeading | async}}</h5> -->
                              </div>
                              <!-- Checkout Page -->
                              <div *ngIf="(headerService.top_heading | async) && !(headerService.home_heading | async)" class="top_heding">
                                  <h3>{{headerService.heading | async}}</h3>
                              </div>
                          </div>
                        </div>
                        <div class="menu-right pull-right" *ngIf="url && !(url.indexOf('/cart') > -1) && !(url.indexOf('/checkout') > -1) && !(url.indexOf('/orders?') > -1) && baseService.isMobile">
                           <div class="right-menu-items"></div>
                          <app-header-widgets [shoppingCartItems]="shoppingCartItems.length"></app-header-widgets>
                        </div>
                       <!-- <div class="menu-left1" *ngIf="baseService.isMobile && !(url.indexOf('/login') > -1)"> -->
                         <div class="menu-left1" *ngIf="baseService.isMobile">
                          <!-- <a class="menu-offers-link" [routerLink]="'/'+baseService?.storeType+'/home'" *ngIf="url && (url.indexOf('cart') > -1 || url.indexOf('/collection/') > -1 || url.indexOf('/restaurants/') > -1)">
                            Offers
                          </a> -->
                           <a _ngcontent-ivr-c2="" target="_blank" class="ng-star-inserted" *ngIf="baseService?.storeData?.home_page_display_number_type && baseService?.storeData?.home_page_display_number_type == '1'" href="tel:{{baseService?.storeData?.home_page_display_number}}">
                              <i _ngcontent-ivr-c2="" aria-hidden="true" class="fa fa-phone sidebar-bar"></i>
                          </a>
                          <a _ngcontent-ivr-c2="" target="_blank" class="ng-star-inserted whatsapp" *ngIf="baseService?.storeData?.home_page_display_number_type && baseService?.storeData?.home_page_display_number_type == '0'" href="https://wa.me/{{baseService?.storeData?.home_page_display_number}}">
                           <img src="assets/images/whatsapp-phone.png" alt="">
                            <!-- <i class="fa fa-whatsapp" aria-hidden="true"></i> -->
                        </a>
                        </div> 
                        <!-- <div class="main_filter" *ngIf="url.indexOf('/collection/') > -1">
                            <div class="filter-main-btn">
                                <span class="filter-btn btn " (click)="mobileFilter()">
                                <img _ngcontent-cvj-c2="" alt="" app-header-widgetsg src="assets/images/filter_icon.png">  
                                </span>
                            </div>
                        </div> -->
                    </div>
                    <div class="main-menu border-section border-top-0" *ngIf="baseService.isDesktopDevice">
                      <!-- <div class="col-lg-3">
                        <div class="brand-logo layout2-logo headerlogo">
                            <a [routerLink]="'/'+baseService?.storeType+'/home'" *ngIf="storeData && storeData.logo"><img src="{{storeData.logo}}" class="img-fluid" alt="store-logo"></a>
                        </div>
                      </div> -->
                      <div class="col-lg-12">
                        <div class="menu-right" *ngIf="baseService.isDesktopDevice">
                           <app-header-widgets [shoppingCartItems]="shoppingCartItems.length"></app-header-widgets>
                        </div>
                      </div> 
                    </div>  
                </div>
            </div>
        </div>
    <!-- </div> -->
  </div>
  <!-- Collection Sidebar -->
  <div class="col-sm-3 collection-filter" id="cat-selection-sidebar" *ngIf="baseService.isMobile">
     <div class="coll_sidebar_sticky">
        <div class="collection-filter-block my_block">
           <app-categories [categories]=baseService?.categories></app-categories>
        </div>
     </div>
  </div>
  <!-- End Collection Sidebar -->
</header>
<!-- header end -->
<!-- Search -->
<app-search></app-search>
<!-- End Search -->
<!-- Share Popup -->
<div class="modal share_popup" id="shareLink">
  <div class="modal-dialog modal-dialog-centered" role="document">
     <div class="modal-content">
        <div class="modal-body share_platforms">
           <h5 class="modal-title" id="exampleModalLabel">{{this.baseService?.storeData?.is_referer_fn_enable ? ('invite-earn' | translate) : ('share' | translate)}}</h5>
           <div class="btn-group" style="width:100%" *ngIf="!referApiHit">
              <button mat-fab shareButton="facebook" [style.backgroundColor]="share.prop.facebook.color"url="{{shareUrl}}">
              <fa-icon [icon]="share.prop.facebook.icon" size="lg" autoSetMeta="false"></fa-icon>
              </button>
              <button mat-fab shareButton="whatsapp" [style.backgroundColor]="share.prop.whatsapp.color" description="{{referMessage}}" title="{{storeName}}">
                 <fa-icon [icon]="share.prop.whatsapp.icon" size="lg" autoSetMeta="false"></fa-icon>
              </button>
              <button mat-fab shareButton="twitter" [style.backgroundColor]="share.prop.twitter.color" description="{{referMessage}}" title="{{storeName}}" url="{{shareUrl}}">
                 <fa-icon [icon]="share.prop.twitter.icon" size="lg" autoSetMeta="false"></fa-icon>
              </button>
              <button mat-fab shareButton="linkedin" [style.backgroundColor]="share.prop.linkedin.color" url="{{shareUrl}}">
                 <fa-icon [icon]="share.prop.linkedin.icon" size="lg" autoSetMeta="false"></fa-icon>
              </button>
           </div>
           <div class="btn-group" style="width:100%" *ngIf="!referApiHit">
              <button mat-fab shareButton="copy" (click)="copyMessage()" [style.backgroundColor]="share.prop.copy.color" description="{{referMessage}}" url="{{shareUrl}}">
              <fa-icon [icon]="share.prop.copy.icon" size="lg"></fa-icon>
              </button>
              <button mat-fab shareButton="sms" [style.backgroundColor]="share.prop.sms.color" description="{{referMessage}}" url="{{shareUrl}}">
                 <fa-icon [icon]="share.prop.sms.icon" size="lg"></fa-icon>
              </button>
              <button mat-fab shareButton="email" [style.backgroundColor]="share.prop.email.color" description="{{referMessage}}" url="{{shareUrl}}">
                 <fa-icon [icon]="share.prop.email.icon" size="lg"></fa-icon>
              </button>
              <button mat-fab shareButton="messenger" [style.backgroundColor]="share.prop.messenger.color" description="{{referMessage}}" url="{{shareUrl}}">
                 <fa-icon [icon]="share.prop.messenger.icon" size="lg"></fa-icon>
              </button>
           </div>
           <div class="col-lg-12 checkout-loader col-sm-12 col-xs-12 centerAlign" *ngIf="referApiHit">
              <img src="./assets/images/loader.gif" alt="loading">
           </div>
           <!-- <input type="hidden" id="shareDiv" name="referMessage" [(ngModel)]="referMessage" /> -->
           <div class="model_bottom">
              <button type="button" class="close"  (click)="closeShareModal()">
              {{'cancel' | translate}}
              </button>
           </div>
        </div>
     </div>
  </div>
</div>
<!-- Refer Alert Popup -->
<div class="modal fade cart_model" id="refererAlertPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
     <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
           <div class="row check-out centerAlign Invite-Earn">
              <div class="select-option-title">
                 <h4>{{'invite-earn' | translate}}</h4>
                 <div class="line-two"></div>
              </div>
              <div class="col-lg-12 col-sm-12 col-xs-12 order-type-container earn_popup p-0">
                 <h5 class="mb-0">
                    {{'please-log-in-to-share-your' | translate}}<br>
                    <strong>{{'refferal-code-and-earn-a' | translate}}</strong><br>
                    <strong>{{'discount-coupon' | translate}}</strong><br>
                    {{'on-your-next-order' | translate}}.
                 </h5>
              </div>
              <div class="col-lg-12 col-sm-12 col-xs-12 order-type-container earn_popup p-0 offers-apply">
                 <button class="btn btn-primary" (click)="goForLogin()">{{'go-for-login' | translate}}</button>
              </div>
           </div>
        </div>
     </div>
  </div>
</div>
<div *ngIf="mobilesaerchFlag == true" class="modal fade" id="addressByRadius1" tabindex="-1" role="dialog" aria-labelledby="addressByRadius1" aria-hidden="true"  data-backdrop="static" style="display: none;">
  <div class="modal-dialog modal-dialog-centered" role="document">
     <div class="modal-content">
        <div class="modal-header">
           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
           <span aria-hidden="true">&times;</span>
           </button>
        </div>
        <div class="modal-body">
           <h5 class="modal-title" id="exampleModalLabel">{{'set-location' | translate}}</h5>
           <!-- <div class="form-group col-md-12 col-sm-12 col-xs-12">
              <div class="field-label">Town/City</div>
              <select (change)="showAreas($event.target.value)" class="form-control" name="town" [formControl]="checkoutFormMap.controls['town']">
                  <option value="" selected>--- Select Town ---</option>
                  <option *ngFor="let area of allAreas" value="{{area.area_id+'~~'+area.area}}">{{area.area}}</option>
              </select>
              <div *ngIf="checkoutFormMap.controls.town.touched && checkoutFormMap.controls.town.errors?.required" class="text text-danger">
                  City is required
              </div>
              </div> -->
           <!-- <div class="form-group">
              <label>Enter address</label>
              <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #searchmap>
              </div>   --> 
           <form class="locate_form">
              <div class="location-search-block">   
                 <a  class="loc-search-icon"><i  class="fa fa-search"></i></a>          
                 <input type="text" class="form-control" id="search-location" (keydown.enter)="$event.preventDefault()" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" (click)="clearSearchInput()" [placeholder]="formattedAddress" #search>
              </div>
              <div class="form-group col-md-12 col-sm-12 col-xs-12 p-0">
                 <!-- <div class="field-label">Address : </div> -->
                 <div class="address_map">
                    <agm-map
                    [latitude]="maplat"
                    [longitude]="maplng"
                    [zoom]="zoom"
                    [disableDefaultUI]="false"
                    [zoomControl]="true" [scrollwheel]="true">
                    <agm-marker [latitude]="maplat" [longitude]="maplng" [markerDraggable]="true" (dragEnd)="markerDragEnd($event)">
                    </agm-marker>
                    </agm-map>
                 </div>
                 <!--  <div id="pickUpMap"></div> -->
              </div>
              <div class="modal-footer">
                 <!-- <button [disabled]="!checkoutFormMap.valid" type="submit" class="btn ">Submit</button> -->
                 <button (click)="closeMap()">{{'submit' | translate}}</button>
              </div>
           </form>
        </div>
     </div>
  </div>
</div>