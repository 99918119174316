import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Services
import { WINDOW_PROVIDERS } from "./services/windows.service";
import { LandingFixService } from '../shared/services/landing-fix.service';
//import { InstagramService } from "./services/instagram.service";
//import { ProductsService } from "./services/products.service";
//import { ShopService } from '../shop/shop.service';
import { CartService } from "./services/cart.service";
//import { OrderService } from "./services/order.service";
import { PaginationService } from "./classes/paginate";
// Pipes
import { OrderByPipe } from './pipes/order-by.pipe';
// components
import { HeaderThreeComponent } from './header/header-three/header-three.component';
import { TopbarOneComponent } from './header/widgets/topbar/topbar-one/topbar-one.component';
import { NavbarComponent } from './header/widgets/navbar/navbar.component';
import { SettingsComponent } from './header/widgets/settings/settings.component';
import { LeftMenuComponent } from './header/widgets/left-menu/left-menu.component';
import { FooterOneComponent } from './footer/footer-one/footer-one.component';
// import { InformationComponent } from './footer/widgets/information/information.component';
import { CategoriesComponent } from './footer/widgets/categories/categories.component';
import { WhyWeChooseComponent } from './footer/widgets/why-we-choose/why-we-choose.component';
import { CopyrightComponent } from './footer/widgets/copyright/copyright.component';
import { SocialComponent } from './footer/widgets/social/social.component';
import { HttpService } from './services/http.service';
// import { BaseService } from './services/base.service';
import { CookieService } from 'ngx-cookie-service';
import {ShareButtonModule} from '@ngx-share/button';
import { SafeHtmlPipe } from './pipes/safeHTML.pipe'

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SearchComponent } from './search/search.component';
import { ProductBoxVerticalComponentShared } from './product-box-vertical/product-box-vertical.component';
import { ProductSliderFourComponent } from './product-slider/product-slider.component';
import { ProductWebViewComponent } from './product-web-view/product-web-view.component';
import { AgmCoreModule } from '@agm/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { LazyLoadImageModule } from 'ng-lazyload-image';    
import { NgSelectModule } from '@ng-select/ng-select';
import { CuisinesComponent } from '../shop/home-4/cuisines/cuisines.component';
import { SliderFourComponent } from '../shop/home-4/slider/slider.component';
import { WebSearchComponent } from '../shop/home-4/web-search/web-search.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  exports: [
    CommonModule,
    TranslateModule,
    HeaderThreeComponent,
    FooterOneComponent,
    OrderByPipe,
    ProductBoxVerticalComponentShared,
    NgSelectModule,
    ProductSliderFourComponent,
    ProductWebViewComponent,
    CuisinesComponent,
    SliderFourComponent,
    WebSearchComponent,
    SafeHtmlPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    ShareButtonModule.withConfig({
      theme: 'material-dark'
    }),
    AgmCoreModule,
    SlickCarouselModule,
    LazyLoadImageModule,
    NgSelectModule,
  ],
  declarations: [
    HeaderThreeComponent,
    FooterOneComponent,
    OrderByPipe,
    NavbarComponent,
    SettingsComponent,
    LeftMenuComponent,
    TopbarOneComponent,
    // InformationComponent,
    CategoriesComponent,
    WhyWeChooseComponent,
    CopyrightComponent,
    SocialComponent,
    SearchComponent,
    ProductBoxVerticalComponentShared,
    ProductSliderFourComponent,
    ProductWebViewComponent,
    CuisinesComponent,
    SliderFourComponent,
    WebSearchComponent,
    SafeHtmlPipe
  ],
  providers: [
    WINDOW_PROVIDERS,
    LandingFixService,
    //InstagramService,
   // ProductsService,
   // ShopService,
    CartService,
    //OrderService,
    PaginationService,
    HttpService,
    CookieService
  ]
})
export class SharedModule { }
