import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router,NavigationEnd } from '@angular/router';
import { environment as env} from 'src/environments/environment';
import { BaseService } from './shared/services/base.service';
import { HttpService } from './shared/services/http.service';
declare var $: any;
declare let window: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
   public validStore = true;
   public url;
   public host;
   public localDomains = ["localhost:4200"];
   public stagingDomains = ["https://devadmin.afroyum.com/"];
  //  public stagingDomains = ["devadmin.afroyum.com", "localhost:4200"];
   public deviceData = "device_id=1111&device_token=111&platform=web";
   constructor(translate: TranslateService,
      private router: Router,public baseService:BaseService, public httpService: HttpService) {
      this.url  = window.location.href;
      this.host = window.location.host; 
      //console.log('This is host', this.host);
      this.getStoreId();  
      translate.setDefaultLang('eng');
      //translate.addLangs(['en', 'fr']);
   }

    getStoreId(){
      if(this.stagingDomains.indexOf(this.host) > -1)
      {
        env.base_url  = "https://devadmin.afroyum.com/";
      }
      if (this.localDomains.indexOf(this.host) > -1) {
        this.baseService.storeId = 1;
        //this.baseService.currentStoreId.next(this.baseService.storeId);
        this.baseService.brandID.next(this.baseService.storeId);
        localStorage.setItem('marketPlaceBrandId', this.baseService.storeId);
        this.baseService.storeDetails(this.deviceData);
        this.baseService.getCategories();
        //this.baseService.multipStoreData();        
       } else{
        const hostData = "host="+this.host;
        this.httpService.post('settings/checkDomainName',hostData).subscribe((res) => {
          if (res['success'] && res['data'] != 0) {
            this.baseService.storeId = res['data'];
            this.baseService.brandID.next(this.baseService.storeId);
            localStorage.setItem('marketPlaceBrandId', this.baseService.storeId);
            this.baseService.storeDetails(this.deviceData);
            this.baseService.getCategories();
            //this.baseService.multipStoreData();
          }else{
            this.validStore = false;
          }
        });
       }
    } 

    ngOnInit() {
      [
        // {
        //   type: 'script',git 
        //   url: 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js',
        //   // options: { defer: true}
        // },
        // https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/fontawesome.min.css
        // {
        //   type: 'script',
        //   url: 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js',
        //   // options: { defer: true}
        // },
        {
          type: 'script',
          url: 'https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.4.1/js/bootstrap.min.js',
          // options: { defer: true}
        },
        {
          type: 'link',
          // url: 'https://fonts.googleapis.com/css?family=Lora:400,700'
          url: 'https://fonts.googleapis.com/css?family=Lora:400,700|Pacifico'
        },
        // {
        //   type: 'link',
        //   url: 'https://fonts.googleapis.com/css?family=Pacifico'
        // },
        {
          type: 'link',
          url: 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css'
        },
        {
          type: 'link',
          url: 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css'
        },
        // {
        //   type: 'script',
        //   url: 'https://cdnjs.cloudflare.com/ajax/libs/jquery.smartmenus/1.1.1/jquery.smartmenus.min.js',
        //   // options: { defer: true}
        // },
      ].forEach(item => {
        this.loadScript(item.type, item.url);
      });


        // (function(d, m){
        // var kommunicateSettings = {"appId":"3fa16425bc95756a95d34b6ec6710c56d","popupWidget":true,"automaticChatOpenOnNavigation":true};
        // var s = document.createElement("script"); s.type = "text/javascript"; s.async = true;
        // s.src = "https://widget.kommunicate.io/v2/kommunicate.app";
        // var h = document.getElementsByTagName("head")[0]; h.appendChild(s);
        // window.kommunicate = m; m._globals = kommunicateSettings;
        // })(document, window.kommunicate || {});
    }

    ngAfterViewInit() {
      $.getScript('assets/js/script.js');
    }

  loadScript(type, src, options: { async, defer } = { async: false, defer: false }) {
    if (type === 'link') {
      const node = document.createElement('link');
      node.href = src;
      // node.type = 'stylesheet';
      node.rel = 'stylesheet';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
    if (type === 'script') {
      const node = document.createElement('script');
      node.src = src;
      node.type = 'text/javascript';
      node.async = options.async;
      node.defer = options.defer;
      document.getElementsByTagName('head')[0].appendChild(node);

    }
  }
 
}
